import { useContext } from "react";
import authContext from "../../context/AuthContext";

const useUserData = () => {
  const userData = useContext(authContext);

  return userData;
};

export default useUserData;
