import useUserPermissions from "../../hooks/Auth/useUserPermissions";

const CanSwapAccount = (props) => {
  const { userCan } = useUserPermissions();

  if (userCan("account.swap")) {
    return <>{props.children}</>;
  }

  return null;
};

export default CanSwapAccount;
