import classes from "./Error.module.css";

const Error = () => {
  return (
    <div className={classes.error}>
      <span>
        Se ha producido un error. Inténtalo mas tarde o contacta a tu taller
        MotorVinilo{" "}
        <a href="mailto:ventas@motorvinilo.com" className={classes.link}>
          ventas@motorvinilo.com
        </a>
      </span>
    </div>
  );
};

export default Error;
