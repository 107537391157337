import classes from "./UserManagement.module.css";
import MainContainer from "../../../components/ui/MainContainer";
import CompanyUsers from "../components/CompanyUsers";
import { useNavigate } from "react-router-dom";
import CanCreateUsers from "../../../components/Permissions/CanCreateUsers";

const UserManagement = () => {
  const navigate = useNavigate();

  const newUserHandler = () => navigate("/user/create");

  const updateProfileHandler = () => navigate("/user/edit");

  return (
    <MainContainer>
      <h1>Perfil de usuario</h1>
      <main className={classes.main}>
        <h3>Gestionar usuarios</h3>

        <div className={classes.buttons}>
          <button className="btn-secondary" onClick={updateProfileHandler}>
            Actualizar perfil
          </button>
          <CanCreateUsers>
            <button className={`btn-active`} onClick={newUserHandler}>
              Registra nuevo usuario
            </button>
          </CanCreateUsers>
        </div>

        <CanCreateUsers>
          <CompanyUsers />
        </CanCreateUsers>
      </main>
    </MainContainer>
  );
};

export default UserManagement;
