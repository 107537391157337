import { useEffect, useState } from "react";

const usePoints = (price = 0) => {
//   console.log(price);

  const [points, setPoints] = useState(null);

  useEffect(() => {
    const value = Math.round(price / 10);

    setPoints(value);
  }, [price]);

  return points;
};

export default usePoints;
