import { useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MemoizedDetailRow from "../../../components/DetailRow/DetailRow";
import Card from "../../../components/ui/Card/Card";
import Error from "../../../components/ui/Error/Error";
import Loading from "../../../components/ui/Loading/Loading";
import usePageTitle from "../../../hooks/usePageTitle";
import { price } from "../../../lib/price";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./QuoteDetails.module.css";
import Breadcrumbs from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import Chip from "../../../components/Quotes/status/Chip";
import useQuote from "../../../hooks/Quotes/useQuote";

const QuoteDetails = ({ title }) => {
  usePageTitle(title);

  const { quoteId } = useParams();
  const navigate = useNavigate();

  const { quoteDetails, order } = useQuote(quoteId);

  const memoizedSubTotal = useMemo(() => {
    return quoteDetails?.data?.rows.reduce(
      (partialSum, row) => partialSum + +row.price * row.units,
      0
    );
  }, [quoteDetails?.data?.rows]);

  const discount =
    memoizedSubTotal * (quoteDetails?.data?.rows[0].discount / 100);

  const vat = (memoizedSubTotal - discount) * 0.21;

  const total = memoizedSubTotal - discount + vat;

  const transformToOrderHandler = () => {
    order.mutate(quoteId, {
      onSuccess: (data) => navigate(`/orders/${data.id}`),
    });
  };

  if (quoteDetails.isLoading) {
    return <Loading />;
  }

  if (quoteDetails.isError) {
    <Error />;
  }

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <div className={classes.header}>
        <h1>Presupuesto: {quoteDetails?.data?.reference}</h1>
        <Chip status={quoteDetails?.data?.status} />
      </div>

      <div className={classes.sections}>
        <p className={classes.title}>Resumen</p>
        <Card>
          <div className={classes.abstract}>
            <div className={classes.abstractDetails}>
              <p className={classes.bold}>Vehículo:</p>
              <p className={classes.content}>
                {quoteDetails?.data?.generation_name}
              </p>

              <p className={classes.bold}>Fecha:</p>
              <p className={classes.content}>
                {quoteDetails?.data?.created_at}
              </p>

              <p className={classes.bold}>Id presupuesto:</p>
              <p className={classes.content}>#{quoteDetails?.data?.id}</p>

              <p className={classes.bold}>Total IVA incluido:</p>
              <div className={classes.finalPrice}>
                <p className={`${classes.content} ${classes.price}`}>
                  {price(total)}
                </p>
                <p>(IVA incluido)</p>
              </div>
            </div>
          </div>
        </Card>

        <p className={classes.title}>Detalle</p>
        <Card>
          <div className={classes.details}>
            {/* Listado de productos/servicios presupuestados */}
            <div className={classes.details}>
              <MemoizedDetailRow rows={quoteDetails?.data?.rows} />
            </div>

            {/* Subtotal, descuento, IVA y total */}
            <div className={classes.total}>
              <p className={classes.bold}>Subtotal:</p>
              <p>{price(memoizedSubTotal)}</p>

              <p className={classes.bold}>Descuento:</p>
              <p>{price(discount)}</p>

              <p className={classes.bold}>IVA:</p>
              <p>{price(vat)}</p>

              <p className={classes.bold}>Total:</p>
              <p>{price(total)}</p>
            </div>
          </div>
        </Card>

        {quoteDetails.data?.notes && (
          <>
            <p className={classes.title}>Notas</p>
            <Card>
              <p>{quoteDetails.data?.notes}</p>
            </Card>
          </>
        )}
      </div>

      {/* Botones para volver atrás y hacer pedido */}
      <div className={classes.footer}>
        <div className={classes.btn}>
          <Link
            to="/quotes"
            style={{
              textDecoration: "none",
              color: "black",
              fontSize: ".9rem",
            }}>
            <FontAwesomeIcon
              icon={faAngleLeft}
              style={{ marginRight: "5px" }}
              className={classes.footerIcon}
            />
            Todos mis presupuestos
          </Link>
        </div>
        <div className={classes.btn}>
          <input
            type="button"
            value={`${
              quoteDetails?.data?.status != 2
                ? "Realizar pedido"
                : "Pedido procesado"
            }`}
            className={`${classes.footerLink} ${
              quoteDetails?.data?.status != 2 ? "btn-active" : "btn-disabled"
            }`}
            disabled={quoteDetails?.data?.status == 2}
            onClick={transformToOrderHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteDetails;
