import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getCartProducts } from "../../lib/cart";
import { success, toastError } from "../../lib/toast";
import useUserData from "../Auth/useUserData";
import useQuote from "../Quotes/useQuote";
import { saveQuote } from "./../../lib/quote";

const useCartProductsList = () => {
  const { token } = useUserData();

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const cartProductsListQuery = useQuery(
    ["cartList", token],
    () => {
      const products = getCartProducts(token);
      return products;
    },
    {
      onError: () => toastError(`Se ha producido un error`),
    }
  );

  const saveCart = useMutation(
    async (cartProducts) => {
      const result = saveQuote(token, cartProducts);
      return result;
      // funcions
    },
    {
      onSuccess: () => {
        success();
        queryClient.invalidateQueries("settings");
        queryClient.invalidateQueries("cartList");
        queryClient.invalidateQueries("userPoints");
        navigate("/quotes");
      },
      onError: () => toastError(),
    }
  );

  const saveAndOrder = useMutation(
    async (cartProducts) => {
      const result = saveQuote(token, cartProducts);
      return result;
    },
    {
      success: (data) => console.log(data),
    }
  );

  return { cartProductsListQuery, saveCart };
};

export default useCartProductsList;
