import Context from "./../../context/SessionContext";
import classes from "./Home.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import usePageTitle from "../../hooks/usePageTitle";
import MainService from "../../components/MainServices";
import FastIcon from "../../components/ui/icons/FastIcon";
import MoneyIcon from "../../components/ui/icons/MoneyIcon";
import AttentionIcon from "../../components/ui/icons/AttentionIcon";
import useUserPoints from "../../hooks/useUserPoints";
import Loading from "../../components/ui/Loading/Loading";
import Error from "../../components/ui/Error/Error";
import InformationIcon from "../../components/ui/icons/InformationIcon";
import { Tooltip } from "react-tippy";
import PointsIndicador from "../../components/PointsIndicator";
import useUserPermissions from "../../hooks/Auth/useUserPermissions";
import CanSwapAccount from "../../components/Permissions/CanSwapAccount";

const Home = ({ title }) => {
  usePageTitle(title);

  const { userCan } = useUserPermissions();

  const pointsQuery = useUserPoints();

  if (pointsQuery.isLoading) {
    return <Loading />;
  }

  if (pointsQuery.isError) {
    return <Error />;
  }

  return (
    <>
      <div className={classes.container}>
        <h2>Bienvenido a tu perfil de MotorVinilo</h2>

        <CanSwapAccount>
          <h1>El usuario puede actualizar pefil</h1>
        </CanSwapAccount>

        <div className={classes.points}>
          <h3>Tienes acumulados</h3>
          <PointsIndicador points={pointsQuery.data?.points} />
        </div>
      </div>

      <div className={`${classes.container} ${classes.cta}`}>
        <Link to="/quotes/new">
          <input
            type="button"
            className="btn btn-active responsive-btn"
            value={"Crear presupuesto"}
          />
        </Link>
      </div>

      <div className={classes.main}>
        <div className={classes.magazine}>
          <Link to="/quotes" className={`${classes.option} ${classes.quotes}`}>
            <div>Mis presupuestos</div>
          </Link>

          <Link to="/orders" className={`${classes.option} ${classes.orders}`}>
            <div>Mis pedidos</div>
          </Link>
        </div>
      </div>

      <div className={classes.container}>
        <div className={classes.sections}>
          <div className={classes.section}>
            <div className={classes.content}>
              <h3>Ahora más rápido</h3>
              <p>Haz tus pedidos a MotorVinilo dirrectamente.</p>
            </div>
            <FastIcon className={classes.icon} />
          </div>

          <div className={classes.section}>
            <div className={classes.content}>
              <h3>Ahorro</h3>
              <p>
                Consigue descuentos exlusivos con nuestro sistema de puntos.
              </p>
            </div>
            <MoneyIcon className={classes.icon} />
          </div>

          <div className={classes.section}>
            <div className={classes.content}>
              <h3>Atención personalizada</h3>
              <p>¿Alguna duda? Contacta con nosotros</p>
            </div>
            <AttentionIcon className={classes.icon} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
