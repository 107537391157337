import { useQuery } from "react-query";
import { getSettings } from "../../lib/settings";
import { toastError } from "../../lib/toast";
import useUserData from "../Auth/useUserData";

const useSettings = () => {
  const { token } = useUserData();

  const settingsQuery = useQuery(
    ["userSettings", token],
    async () => {
      const result = await getSettings(token);
      return result;
    },
    {
      onError: () => toastError(),
    }
  );

  return { settingsQuery };
};

export default useSettings;
