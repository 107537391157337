import React from "react";
import { price } from "../../lib/price";
import TrashIcon from "../ui/icons/TrashIcon";
import classes from "./DetailRow.module.css";

const DetailRow = (props) => {
  const removeHandler = (rowId) => {
    props.onDelete(rowId);
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Nombre del producto</th>
            <th>Material</th>
            <th className={classes.number}>Precio unitario</th>
            <th className={classes.number}>Unidades</th>
            <th className={classes.number}>Total</th>
          </tr>
        </thead>
        <tbody>
          {props.rows?.length > 0 &&
            props.rows.map((row) => {
              return (
                <tr key={row.id}>
                  <td>{row.product || row.product_name}</td>
                  <td>{row.material || row.material_name}</td>
                  <td className={classes.number}>{price(row.price)}</td>
                  <td className={classes.number}>{row.units}</td>
                  <td className={classes.number}>
                    {price(row.units * row.price)}
                  </td>
                  {props.delete && (
                    <td
                      className={classes.remove}
                      onClick={() => removeHandler(row.id)}
                    >
                      <TrashIcon />
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

const MemoizedDetailRow = React.memo(DetailRow);

export default MemoizedDetailRow;
