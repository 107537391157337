export const SERVICES = [
  {
    brand: "Mini",
    data: [
      {
        service: "Vinilado carrocería completa (exterior y/o pasos de puerta)",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/10.jpg",
          "https://www.motorvinilo.com/blog/fotos/mini-negro-mate-antiguo/mini_cooper_antes_despues.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-serie-3-gt-forrado-en-vinilo-gris-mate-metalizado-y-detalles-en-negro/bmw_3_gt_vinilo_gris_mate_oscuro.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-z3-gris-oscuro-mate-m261/bmw-z3-gris-oscuro-mate.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-m5-acero-cepillado/dscf9682.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-z3-charcoal-mate/bmw-z3-charcoal-mate-lateral-copi.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-serie-1-cabrio-forrado-en-vinilo-rojo-brillante-y-llantas-en-negro-mate/bmw_s1_cabrio_vinilo_rojo_brillante_metal_llantas_negro_mate.jpg",
        ],
      },
      {
        service: "Vinilado molduras interiores",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/cache/2359__660x_antes-desp.jpg",
          "https://www.motorvinilo.com/blog/fotos/cache/2354__500x_1080_interior_carbono_3m.jpg",
          "https://www.motorvinilo.com/blog/fotos/cache/2371__660x_mini_cooper_manita_puerta.jpg",
        ],
      },
      {
        service: "Vinilado techo, alerón o montantes",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/2.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/5.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-x1-techo-negro-brillante/bmwx1_vinilo_techo.jpg",
        ],
      },
      {
        service: "Vinilado cromados (marco ventanas, parrilla, molduras)",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/12.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/8.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/9-1.jpg",
        ],
      },
      {
        services: "Vinilado llantas",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/13.jpg",
        ],
      },
      {
        service: "Franjas y diseños personalizados",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/1-2.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/11-1.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/7.jpg",
          "https://www.motorvinilo.com/blog/fotos/antes-y-despues/antesdespues_franjas_negro_.jpg",
        ],
      },
    ],
  },
  {
    brand: "BMW",
    data: [
      {
        service: "Vinilado carrocería completa (exterior y/o pasos de puerta)",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/10.jpg",
          "https://www.motorvinilo.com/blog/fotos/mini-negro-mate-antiguo/mini_cooper_antes_despues.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-serie-3-gt-forrado-en-vinilo-gris-mate-metalizado-y-detalles-en-negro/bmw_3_gt_vinilo_gris_mate_oscuro.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-z3-gris-oscuro-mate-m261/bmw-z3-gris-oscuro-mate.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-m5-acero-cepillado/dscf9682.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-z3-charcoal-mate/bmw-z3-charcoal-mate-lateral-copi.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-serie-1-cabrio-forrado-en-vinilo-rojo-brillante-y-llantas-en-negro-mate/bmw_s1_cabrio_vinilo_rojo_brillante_metal_llantas_negro_mate.jpg",
        ],
      },
      {
        service: "Vinilado molduras interiores",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/cache/2359__660x_antes-desp.jpg",
          "https://www.motorvinilo.com/blog/fotos/cache/2354__500x_1080_interior_carbono_3m.jpg",
          "https://www.motorvinilo.com/blog/fotos/cache/2371__660x_mini_cooper_manita_puerta.jpg",
        ],
      },
      {
        service: "Vinilado techo, alerón o montantes",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/2.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/5.jpg",
          "https://www.motorvinilo.com/blog/fotos/bmw-x1-techo-negro-brillante/bmwx1_vinilo_techo.jpg",
        ],
      },
      {
        service: "Vinilado cromados (marco ventanas, parrilla, molduras)",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/12.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/8.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/9-1.jpg",
        ],
      },
      {
        service: "Vinilado llantas",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/13.jpg",
        ],
      },
      {
        service: "Franjas y diseños personalizados",
        photos: [
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/1-2.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/11-1.jpg",
          "https://www.motorvinilo.com/blog/fotos/tarjetas-antes-despues/7.jpg",
          "https://www.motorvinilo.com/blog/fotos/antes-y-despues/antesdespues_franjas_negro_.jpg",
        ],
      },
    ],
  },
];
