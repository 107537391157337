import classes from "./Chip.module.css";

const Chip = (props) => {
  return (
    <div className={props.status == 1 ? classes.saved : classes.processed}>
      <p>
        {props.status == 1 ? "Presupuesto pendiente" : "Presupuesto aceptado"}
      </p>
    </div>
  );
};

export default Chip;
