import { useQuery } from "react-query";
import { getOrders } from "../../lib/order";
import useUserData from "../Auth/useUserData";

const useOrders = () => {
  const { token } = useUserData();

  const orders = useQuery(["orders", token], () => {
    const result = getOrders(token);
    return result;
  });

  return { orders };
};

export default useOrders;
