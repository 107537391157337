import ReactGA from "react-ga";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink } from "react-router-dom";
import classes from "./Breadcrumbs.module.css";

const Breadcrumbs = () => {
  // Google Analytics
  ReactGA.pageview(window.location.pathname);

  const breadcrumbs = useBreadcrumbs();

  const translate = (bread) => {
    // const translations = ["Home", "quotes", "quote", "cart", "showroom"];
    const translations = [
      { en: "home", es: "Inicio" },
      { en: "quotes", es: "Presupuestos" },
      { en: "quote", es: "Presupuesto" },
      { en: "cart", es: "Carrito" },
      { en: "showroom", es: "Galeria" },
      { en: "new", es: "Crear presupuesto" },
      { en: "services", es: "Servicios habituales" },
      { en: "orders", es: "Pedidos" },
      { en: "contact", es: "Contacto" },
    ];
    const translation = translations.find(
      (tr) => tr.en.toLowerCase() == bread.props.children.toLowerCase()
    );

    if (translation) {
      return translation.es.trim();
    } else {
      return bread.props.children;
    }
  };

  return (
    <div className={classes.breadcrumbs}>
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <span key={match.pathname}>
          <NavLink to={match.pathname} className={classes.link}>
            {index > 0 && <p className={classes.separtor}>{`> `}</p>}
            {translate(breadcrumb)}
          </NavLink>
        </span>
      ))}
    </div>
  );
};

export default Breadcrumbs;
