import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const setSettings = async (values) => {

  const response = await redaxios
    .post(`${domain}/api/settings/store`, values.settings, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${values.token}`,
      },
    })
    .catch((error) => {
      throw new Error(error.message);
    });

  return response.data;
};

export const getSettings = async (token) => {
  // const [key, { token }] = queryKey;

  const response = await redaxios.get(`${domain}/api/settings/`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return response.data;
};

export const getDiscount = async ({ queryKey }) => {
  const [key, { token }] = queryKey;

  const response = await redaxios
    .get(`${domain}/api/settings/discount`, {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      throw new Error(error.message);
    });

  return response.data;
};
