import classes from "./Contact.module.css";
import Card from "./../../components/ui/Card/Card";
import { useRef } from "react";
import Breadcrumbs from "../../components/ui/Breadcrumbs/Breadcrumbs";
import useContactForm from "../../hooks/Contact/useContact";

const Contact = () => {
  const textAreaRef = useRef(null);

  const { addMessageForm } = useContactForm();

  const submitHandler = async () => {
    addMessageForm.mutate(textAreaRef.current.value);
  };

  return (
    <div className={classes.container}>
      <Breadcrumbs />

      <h1>Contacto</h1>

      <Card>
        <div className={classes.content}>
          <div className={classes.form}>
            <label>Escribe tu mensaje aquí:</label>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className={classes.message}
              ref={textAreaRef}
            ></textarea>
            <input
              type="button"
              value="Enviar"
              className={`btn btn-active ${classes.btn}`}
              onClick={submitHandler}
            />
          </div>
          <div className={classes.details}>
            <p className={classes.address}>
              Passeig de Santa Coloma, 49, 51, 08030 Barcelona
            </p>
            <p className={classes.address}>931 13 13 01</p>
            <p className={classes.address}>ventas@motorvinilo.com</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Contact;
