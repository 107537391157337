import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const login = async (email, password) => {
  const response = await redaxios.post(
    `${domain}/api/login`,
    {
      email: email,
      password: password,
    },
    { headers: { Accept: "application/json" } }
  );

  return response.data;
};

export const logout = async (token) => {
  const response = await redaxios.post(
    `${domain}/api/logout`,
    {},
    {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
};
