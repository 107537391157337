import { useContext, useRef, useState } from "react";
import Context from "../../../context/SessionContext";
import useUserData from "../../../hooks/Auth/useUserData";
import { saveReport } from "../../../lib/report";
import { success, toastError } from "../../../lib/toast";
import Modal from "../modal/Modal";
import classes from "./Footer.module.css";

const Footer = (props) => {
  const session = useContext(Context);
  const { token } = useUserData();

  const [isReportOpen, setIsReportOpen] = useState(false);
  const textAreaRef = useRef(null);

  const closeReportHandler = () => setIsReportOpen(false);
  const openReportHandler = () => setIsReportOpen(true);

  const submitHandler = async () => {
    try {
      await saveReport(token, textAreaRef.current.value);
      success(`Su mensaje se ha enviado correctamente`);
    } catch (error) {
      toastError(`No se ha podido completar la operación`);
    } finally {
      closeReportHandler();
    }
  };

  return (
    <footer className={classes.footer}>
      {isReportOpen && (
        <Modal onClose={closeReportHandler}>
          <div className={classes.reportModal}>
            <label htmlFor="">Explicanos que ha ocurrido:</label>
            <textarea name="" id="" ref={textAreaRef} rows="10"></textarea>
          </div>
          <div className={classes.buttons}>
            <input
              type="button"
              value="Cancelar"
              onClick={closeReportHandler}
              className={`btn-secondary btn-cancel ${classes.btn}`}
            />
            <input
              type="button"
              value="Enviar"
              onClick={submitHandler}
              className={`btn-active ${classes.btn}`}
            />
          </div>
        </Modal>
      )}
      {/* <div className={classes.logo}>
        <img
          src="https://static.comunicae.com/photos/notas/1048675/1370795464_logo-motorvinilo.jpg"
          alt="logo motorvinilo"
        />
      </div> */}
      {token && (
        <div className={classes.report} onClick={openReportHandler}>
          <p>Ayuda</p>
        </div>
      )}
      <div className={classes.contact}>
        <p>Contacta con Motorvinilo</p>
        {/* <a href="tel:123-456-7890">123-456-7890</a> */}
        <a href="tel:931131301">931131301</a>
        {/* <a href = "mailto: abc@example.com">Send Email</a> */}
        <a href="mailto: ventas@motorvinilo.com">ventas@motorvinilo.com</a>
      </div>
    </footer>
  );
};

export default Footer;
