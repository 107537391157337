import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const saveQuote = async (token, quote) => {
  // console.log(values);

  const result = await redaxios.post(`${domain}/api/quote/store`, quote, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getQuote = async (token, quoteId) => {
  // const [key, { token, quoteId }] = queryKey;

  const result = await redaxios.get(`${domain}/api/quote/show/${quoteId}`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const quoteRows = async (quoteId, token) => {
  const result = await redaxios.get(`${domain}/api/quote/show/${quoteId}`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  // console.log(result.data.rows);

  return result.data.rows;
};

export const getQuotes = async (token) => {
  // const [key, { token }] = queryKey;

  const result = await redaxios.get(`${domain}/api/quote/`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const createOrder = async (token, quote) => {
  // console.log(quote);

  const response = await redaxios.get(
    `${domain}/api/quote/transform/${quote}`,
    {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
};

export const storeAndCreateOrder = async (values) => {
  const savedQuote = await saveQuote(values);

  savedQuote.generation_id = values.quote.generation_id;

  const order = await createOrder({
    token: values.token,
    quote: {
      ...values.quote,
      ...savedQuote,
      generation_id: values.quote.generation_id,
    },
  });

  return order;
};

export const getOrders = async (token) => {
  // console.log(token);

  const response = await redaxios.get(`${domain}/api/orders`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return response.data;
};
