import classes from "./QuotesList.module.css";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/Header/Header";
import QuotePreview from "../../../components/Quotes/list/QuotePreview";
import Breadcrumbs from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import Card from "../../../components/ui/Card/Card";
import Error from "../../../components/ui/Error/Error";
import Filter from "../../../components/ui/filter/Filter";
import Loading from "../../../components/ui/Loading/Loading";
import ScrollUp from "../../../components/ui/ScrollUp/ScrollUp";
import usePageTitle from "../../../hooks/usePageTitle";
import { createOrder, getQuotes, quoteRows } from "../../../lib/quote";
import { success, toastError } from "../../../lib/toast";
import useUserData from "../../../hooks/Auth/useUserData";
import useQuotes from "../../../hooks/Quotes/useQuotes";
import useQuoteService from "../../../hooks/Quotes/useQuoteService";

const QuotesList = ({ title }) => {
  usePageTitle(title);

  const { token } = useUserData();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [filter, setFilter] = useState("");
  const [filteredQuotes, setFilteredQuotes] = useState([]);

  const { transFormToOrder } = useQuoteService();

  const clickHandler = (quoteId) => {
    navigate(`/quotes/${quoteId}`);
  };

  // Query para listar todos los presupuestos
  // const quotesQuery = useQuery(["quotesList", { token }], getQuotes);
  const { quotesList: quotesQuery } = useQuotes();

  // Mutation para cambiar el estado de la quote
  const quotesMutation = useMutation(createOrder, {
    onSuccess: () => {
      success(`Su petición se ha procesado correctamente.`);
      queryClient.invalidateQueries("quotesList");
    },
    onError: () =>
      toastError(
        `Se ha producido un error en su solicitud, por favor intentelo más tarde o contacte con su centro MotorVinilo.`
      ),
  });

  const orderHandler = async (quote) => {
    // alert(quote.id)

    // Recupera filas Quote
    // const rows = await quoteRows(quote.id, token);

    // quote.rows = [...rows];

    // quotesMutation.mutate({ token, quote: quote });

    transFormToOrder.mutate(quote.id, {
      onSuccess: (data) => {
        success();
        queryClient.invalidateQueries("quotesList");
      },
      onError: () => toastError(),
    });
  };

  const newQuoteHandler = () => {
    navigate(`/quotes/new`);
  };

  const filterHandler = (value) => {
    setFilter(value);
  };

  useEffect(() => {
    if (quotesQuery.data?.length && filter.trim() != "") {
      const filteredQuotes = quotesQuery.data.filter(
        (quote) =>
          quote.reference.toLowerCase().includes(filter.toLocaleLowerCase()) ||
          quote.id == filter
      );

      setFilteredQuotes(filteredQuotes);
    } else {
      if (quotesQuery.data?.length) {
        setFilteredQuotes([...quotesQuery.data]);
      }
    }
  }, [filter, quotesQuery.data]);

  if (quotesQuery.isLoading) {
    return <Loading />;
  }

  if (quotesQuery.isError) {
    return <Error />;
  }

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <ScrollUp />

      <Header className={classes.header}>
        <div onClick={newQuoteHandler} className={classes.newQuote}>
          <input
            type="button"
            className="btn btn-active responsive-btn"
            value={"Crear presupuesto"}
          />
        </div>
        <h1>Presupuestos</h1>
      </Header>

      <>
        <Filter
          onFilter={filterHandler}
          placeholder={`Filtrar presupuestos por nombre o referencia`}
        />
        {!filteredQuotes.length && (
          <span>No se han encontrado resultados para esta búsqueda.</span>
        )}
        {filteredQuotes.length > 0 && (
          <div className={classes.quotes}>
            {filteredQuotes.map((quote) => (
              <Card key={quote.id}>
                <QuotePreview
                  onDetails={clickHandler}
                  onOrder={orderHandler}
                  quote={quote}
                />
              </Card>
            ))}
          </div>
        )}
      </>
    </div>
  );
};

export default QuotesList;
