import classes from "./CreateQuote.module.css";
import { useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Loading from "../../../components/ui/Loading/Loading";
import Error from "../../../components/ui/Error/Error";
import { getDiscount, setSettings } from "../../../lib/settings";
import { materials } from "./../../../lib/materials";
import { products } from "./../../../lib/products";
import Breadcrumbs from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import Header from "../../../components/Header/Header";
import CarIcon from "../../../components/ui/icons/CarIcon";
import Modal from "../../../components/ui/modal/Modal";
import GenerationSelector from "../components/GenerationSelector/GenerationSelector";
import { success, toastError } from "../../../lib/toast";
import Products from "../components/Products/Products";
import CancelIcon from "../../../components/ui/icons/CancelIcon";
import ScrollUp from "../../../components/ui/ScrollUp/ScrollUp";
import usePageTitle from "../../../hooks/usePageTitle";
import InformationIcon from "../../../components/ui/icons/InformationIcon";
import { Tooltip } from "react-tippy";
import { cleanCart } from "../../../lib/cart";
// import { useCartQuery } from "../../../lib/queries";
import { Link } from "react-router-dom";
import Filter from "../../../components/ui/filter/Filter";
import useUserData from "../../../hooks/Auth/useUserData";
import useCartProductsList from "../../../hooks/Cart/useCartProductsList";
import useSettings from "../../../hooks/Settings/useSettings";

const CreateQuote = ({ title }) => {
  usePageTitle(title);

  const { token } = useUserData();

  const queryClient = useQueryClient();

  // Recupero listado de materiales
  const materialsQuery = useQuery(["materials", { token }], materials);

  // Recupera generación seleccionada (si la hay)
  // const generationQuery = useQuery(["generation", { token }], getSettings);
  const { settingsQuery: generationQuery } = useSettings();

  // Dependent Query para obtener listado de productos
  const generationId = generationQuery?.data?.generation_id;

  // LIstado de productos en cart
  const { cartProductsListQuery: cartQuery } = useCartProductsList();

  const productsQuery = useQuery(
    ["products", { token, generationId }],
    products,
    { enabled: !!generationQuery.data?.generation_id }
  );

  // Mutation para actualizar la generación seleccionda
  const setGenerationMutation = useMutation(setSettings, {
    onSuccess: (data) => {
      queryClient.setQueryData(["generation", { token }], data);
      queryClient.invalidateQueries("userSettings");
    },
  });

  // Query para obtener el descuento del usuario
  const discountQuery = useQuery(["discount", { token }], getDiscount);

  const [isGenerationModalOpen, setIsGenerationModalOpen] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);

  const confirmResetHandler = async (option) => {
    if (option) {
      try {
        await cleanCart(token);
        // setIsGenerationModalOpen(true);
        queryClient.invalidateQueries("cartList");
        success(`Carrito vaciado`);
        setConfirmReset(false);
        setIsGenerationModalOpen(true);
      } catch (error) {
        toastError();
      }
    } else {
      setConfirmReset(false);
      // setIsGenerationModalOpen(true);
    }
  };

  const openResetModalHandler = () => {
    setConfirmReset(true);
  };

  const onCloseResetModal = () => setConfirmReset(false);

  // Handler abre modal para seleccionar generación
  const openGenerationModalHandler = async () => {
    generationId && cartQuery.data.length > 0
      ? openResetModalHandler()
      : setIsGenerationModalOpen(true);
  };

  // Handler cierra modal de selección de generación
  const closeGenerationModalHandler = () => setIsGenerationModalOpen(false);

  // Handler generación seleccionada
  const selectGenerationhandler = (selection) => {
    try {
      // Guarda la selección de la generación en el backend
      setGenerationMutation.mutate({
        token: token,
        settings: {
          generation_id: selection.generation.value,
          generation_name: selection.generation.label,
          brand_name: selection.brand.label,
        },
      });
    } catch (error) {
      toastError();
    }
  };

  // Filtro de productos
  const [filter, setFilter] = useState("");

  const cleanFilterHandler = () => {
    setFilter("");
  };

  const filterHandler = (search) => {
    setFilter(search);
  };

  const isIterable = (value) => {
    return Symbol.iterator in Object(value);
  };

  if (
    materialsQuery.isLoading ||
    generationQuery.isLoading ||
    discountQuery.isLoading
  ) {
    return <Loading />;
  }

  if (
    materialsQuery.isError ||
    generationQuery.isError ||
    productsQuery.isError ||
    discountQuery.isError 
    || !isIterable(materialsQuery.data)
  ) {
    return <Error />;
  }

  return (
    <>
      {/* Modal para confirmar cambio de generación y borrar el carrito */}
      {confirmReset && (
        <Modal onClose={onCloseResetModal}>
          <h3>
            Si cambias de vehículo tu carrito quedará vacio. ¿Seguro que deseas
            continuar?
          </h3>

          <div className={classes.buttons}>
            <input
              type="button"
              value="Cancelar"
              onClick={() => confirmResetHandler(false)}
              className="btn-cancel"
            />
            <input
              type="button"
              value="Confirmar"
              onClick={() => confirmResetHandler(true)}
              className="btn-active"
            />
          </div>
        </Modal>
      )}

      {/* Modal para seleccionar generación */}
      {isGenerationModalOpen && (
        <Modal onClose={closeGenerationModalHandler}>
          <GenerationSelector
            token={token}
            onCancel={closeGenerationModalHandler}
            onSelect={selectGenerationhandler}
          />
        </Modal>
      )}

      <div className={classes.container}>
        <Breadcrumbs />

        {/* Botón scroll up */}
        <ScrollUp />

        <Header className={classes.header}>
          <div className={classes.generation}>
            {/* Cuándo NO hay generación seleccionada */}
            {!generationQuery?.data?.generation_name && (
              <input
                type="button"
                className="btn-active"
                value="Seleccione un vehículo"
                onClick={openGenerationModalHandler}
              />
            )}

            {/* Cuándo SI hay una generación seleccionada */}
            {generationQuery?.data?.generation_name && (
              <>
                <div className={classes.generationContainer}>
                  <p
                    className={`${classes.selectedGeneration} ${classes.bold}`}>
                    Vehículo seleccionado:{" "}
                  </p>
                  <CarIcon className={classes.icon} />
                  <p className={classes.selectedGeneration}>
                    {generationQuery.data.brand_name}{" "}
                    {generationQuery.data.generation_name}
                  </p>
                </div>
                <input
                  type="button"
                  className="btn-secondary"
                  value="- Cambiar vehículo -"
                  onClick={openGenerationModalHandler}
                />

                <Tooltip
                  title="Los costes del prespuesto se calculan en función del modelo de vehículo seleccionado por ese motivo sólo se puede presupuestar un vehículo por presupuesto"
                  position="bottom"
                  trigger="click"
                  arrow="true">
                  <InformationIcon />
                </Tooltip>
              </>
            )}
          </div>
        </Header>

        {/* Mensaje no productos encontrados */}
        {productsQuery.data?.length == 0 && (
          <div className={`${classes.products} ${classes.noProducts}`}>
            <h3>
              No hay productos disponibles.{" "}
              <Link
                style={{
                  color: "black",
                  fontSize: "1.05em",
                  textDecoration: "underline",
                }}
                to="/contact">
                Contacta con tu centro MotorVinilo
              </Link>
            </h3>
          </div>
        )}

        {/* Listado de productos */}
        {productsQuery.data?.length > 0 && (
          <div className={classes.products}>
            <h1>Productos</h1>

            {/* Div que muestra icono filtro + filtros si los hay => SÓLO ESTÁ ACTIVO SI HAY UNA GENERACIÓN SELECCIONADA */}
            {!productsQuery.isLoading &&
              !productsQuery.isError &&
              productsQuery.data && (
                <div className={classes.filter}>
                  <Filter
                    onFilter={filterHandler}
                    placeholder={`Filtrar servicios por nombre`}
                  />
                </div>
              )}

            {/* Si hay algún filtro activo se muestra aqui con un chip */}
            {filter !== "" && (
              <div className={classes.filterData}>
                <h4>Filtros activos:</h4>
                <div
                  className={classes.filterText}
                  onClick={cleanFilterHandler}>
                  <p>{filter}</p>
                  <div className={classes.cleanIcon}>
                    <CancelIcon />
                  </div>
                </div>
              </div>
            )}

            <Products
              products={productsQuery.data}
              materials={materialsQuery.data}
              generation={generationQuery.data}
              token={token}
              discount={discountQuery.data.discount}
              filter={filter}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CreateQuote;
