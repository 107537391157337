import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const getOrders = async (token) => {
  const result = await redaxios.get(`${domain}/api/order/`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  //   console.log(result.data.data);

  return result.data.data;
};

export const getOrderDetails = async (token, orderId) => {
  const result = await redaxios.get(`${domain}/api/order/details/${orderId}`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data.data;
};
