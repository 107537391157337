import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const getTags = async ({ queryKey }) => {
  const [key, { token }] = queryKey;

  const result = await redaxios.get(`${domain}/api/gallery/tags`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const getPhotos = async ({ queryKey }) => {
  const [key, { token, color }] = queryKey;

  const result = await redaxios.get(`${domain}/api/gallery/search/${color}`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const getAllPhotos = async ({ queryKey }) => {
  const [key, { token }] = queryKey;

  const result = await redaxios.get(`${domain}/api/gallery/all/`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};
