import classes from "./CreateCompany.module.css";
import CanSwapAccount from "../../../components/Permissions/CanSwapAccount";
import MainContainer from "../../../components/ui/MainContainer";
import { useForm } from "react-hook-form";
import useCompany from "../../../hooks/Company/useCompany";
import { success, toastError } from "../../../lib/toast";
import { useNavigate } from "react-router-dom";

const CreateCompany = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { addCompany } = useCompany();

  const navigate = useNavigate();

  const cancelHandler = () => navigate("/companies");

  const onSubmit = (data) => {
    addCompany.mutate(data, {
      onSuccess: () => {
        success(`Se ha registrado la nueva empresa con exito`);
        navigate("/company");
      },
      onError: () => toastError(`Se ha producido un error`),
    });
  };

  return (
    <MainContainer>
      <CanSwapAccount>
        <h1>Registrar nueva empresa</h1>
        <h4>
          La empresa debe de existir previamente en Gestión y el email debe de
          coincidir
        </h4>

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formBox}>
            <div className={classes.input}>
              <label className="label">Nombre:</label>
              <input
                type="text"
                name="name"
                {...register("company_name", { required: true })}
              />
              {errors.company_name && (
                <span className={classes.errorField}>
                  El campo nombre és obligatorio
                </span>
              )}
            </div>

            <div className={classes.input}>
              <label className="label">Email:</label>
              <input
                type="text"
                name="name"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className={classes.errorField}>
                  El campo nombre és obligatorio
                </span>
              )}
            </div>

            <div className={classes.buttons}>
              <button
                className={`btn-cancel ${classes.btn}`}
                onClick={cancelHandler}>
                Cancelar
              </button>
              <button type="submit" className={`btn-active ${classes.btn}`}>
                Enviar
              </button>
            </div>
          </div>
        </form>
      </CanSwapAccount>
    </MainContainer>
  );
};

export default CreateCompany;
