import classes from "./UpdateProfile.module.css";
import { useForm } from "react-hook-form";
import MainContainer from "../../../components/ui/MainContainer";
import useUsers from "../../../hooks/Auth/useUser";
import { toastError } from "../../../lib/toast";
import { useNavigate } from "react-router-dom";

const UpdateProfile = () => {
  const { updateUser } = useUsers();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = (data) => {
    console.log(data);
    updateUser.mutate(data, {
      onSuccess: () => navigate("/user"),
      onError: (error) => {
        error.status === 422
          ? toastError(`La dirección de email ya está en uso por otro usuario`)
          : toastError(
              `Se ha producido un error por favor intentalo más tarde`
            );
      },
    });
  };

  return (
    <MainContainer>
      <h1>Actualizar perfil</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.formBox}>
          {/* <div className={classes.input}>
            <label htmlFor="name">Nombre</label>
            <input
              type="name"
              name="name"
              {...register("name", { required: true })}
            />
            {errors.name && (
              <span className={classes.errorField}>
                El campo contraseña és obligatorio
              </span>
            )}
          </div> */}
          <div className={classes.input}>
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              name="password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <span className={classes.errorField}>
                El campo contraseña és obligatorio
              </span>
            )}
          </div>
        </div>

        <div className={classes.buttons}>
          <button className={`btn-cancel ${classes.btn}`} onClick={() => navigate('/user')}>Cancelar</button>

          <button className={`btn-active ${classes.btn}`} type="submit">
            Actualizar
          </button>
        </div>
      </form>
    </MainContainer>
  );
};

export default UpdateProfile;
