import classes from "./CompanyUsers.module.css";
import Error from "../../../../components/ui/Error/Error";
import LoadingIcon from "../../../../components/ui/icons/LoadingIcon";
import useUsers from "../../../../hooks/Users/useUsers";
import CanCreateUsers from "./../../../../components/Permissions/CanCreateUsers";
import Card from "../../../../components/ui/Card/Card";

const CompanyUsers = () => {
  const {
    usersQuery: { isLoading, isError, data },
    statusHandler,
  } = useUsers();

  const checkHandler = (userId) => {
    // alert(userId);
    statusHandler.mutate(userId);
  };

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <Card>
      <CanCreateUsers>
        <div className={classes.users}>
          <div className={classes.user}>
            <p className={`${classes.column} ${classes.header}`}>Nombre</p>
            <p className={`${classes.column} ${classes.header}`}>Email</p>
            <p className={`${classes.column} ${classes.header}`}>Activo</p>
          </div>

          {data.map((user) => (
            <div className={`${classes.user} ${classes.row}`} key={user.id}>
              <p className={classes.column}>{user.name}</p>
              <p className={classes.column}>{user.email}</p>
              <div className={classes.column}>
                <input
                  type="checkbox"
                  checked={user.active}
                  onChange={() => checkHandler(user.id)}
                />
              </div>
            </div>
          ))}
        </div>
      </CanCreateUsers>
    </Card>
  );
};

export default CompanyUsers;
