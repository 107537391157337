import useUserData from "./useUserData";
import { useMutation } from "react-query";
import redaxios from "redaxios";
import { success, toastError } from "../../lib/toast";

const useUsers = () => {
  const domain = process.env.REACT_APP_BACKEND_URL;

  const { token } = useUserData();

  const addUser = useMutation(
    async (user) => {
      // console.log(user);
      const { data } = await redaxios.post(`${domain}/api/user/create`, user, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    },
    {
      onSuccess: () => console.log(`ok`),
      onError: () => toastError(),
    }
  );

  const updateUser = useMutation(
    async (user) => {
      const result = await redaxios.post(`${domain}/api/user/edit`, user, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      return result.data;
    },
    {
      onSuccess: () => success(`Perfil actualizado con éxito`),
      // onError: () =>
        // toastError(`Ha ocurrido un eroro vuelve a intentarlo más tarde`),
    }
  );

  return { addUser, updateUser };
};

export default useUsers;
