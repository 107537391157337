import classes from './Loading.module.css';
import { TailSpin } from 'react-loader-spinner';

const Loading = () => {
    return (
        <div className={classes.loading}>
            <TailSpin height={100} width={100} color='gray' />
        </div>
    )
}

export default Loading;