import useLockBodyScroll from "../../../hooks/useLockBodyScroll";
import ReactDOM from "react-dom";
import classes from "./Sidebar.module.css";
import CloseIcon from "../icons/CloseIcon";
import { NavLink } from "react-router-dom";
import LogoutIcon from "../icons/LogoutIcon";
import { createBrowserHistory } from "history";
import useUserPermission from "./../../../hooks/Auth/useUserPermissions";
import CanCreateUsers from "../../Permissions/CanCreateUsers";
import CanSwapAccount from "../../Permissions/CanSwapAccount";

const portalElement = document.getElementById("overlays");

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const Overlay = (props) => {
  return (
    <div className={classes.menu}>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

const Sidebar = (props) => {
  useLockBodyScroll();

  const { userCan } = useUserPermission();

  let history = createBrowserHistory();
  history.listen((location, action) => {
    // console.log(location);
    // console.log(action);
    props.close();
  });

  const logout = () => {
    props.onLogout();
  };

  const linkStyle = ({ isActive }) => {
    return {
      fontSize: "25px",
      color: isActive ? "#FFDF04" : "white",
    };
  };

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <Overlay>
          <div className={classes.container}>
            <div className={classes.content}>
              <NavLink style={linkStyle} to="/" onClick={props.onClose}>
                Inicio
              </NavLink>
              <NavLink
                end
                style={linkStyle}
                to="/quotes/new"
                onClick={props.onClose}>
                Crear presupuesto
              </NavLink>
              <NavLink
                end
                style={linkStyle}
                to="/quotes"
                onClick={props.onClose}>
                Presupuestos
              </NavLink>
              <NavLink
                end
                style={linkStyle}
                to="/orders"
                onClick={props.onClose}>
                Pedidos
              </NavLink>
              <NavLink end style={linkStyle} to="/cart" onClick={props.onClose}>
                Carrito
              </NavLink>
              <NavLink
                end
                style={linkStyle}
                to="/showroom"
                onClick={props.onClose}>
                Galería de fotos
              </NavLink>
              {/* <NavLink
                end
                style={linkStyle}
                to="/services"
                onClick={props.onClose}>
                Servicios destacados
              </NavLink> */}

              {/* Este link sólo se activa si el usuario tiene permiso para gestionar cuentas */}
              <CanCreateUsers>
                <NavLink
                  end
                  style={linkStyle}
                  to="/user"
                  onClick={props.onClose}>
                  Gestion de usuarios
                </NavLink>
              </CanCreateUsers>

              <CanSwapAccount>
                <NavLink
                  end
                  style={linkStyle}
                  to="/companies"
                  onClick={props.onClose}>
                  Gestion de empresas
                </NavLink>
              </CanSwapAccount>

              <NavLink
                end
                style={linkStyle}
                to="/contact"
                onClick={props.onClose}>
                Contacto
              </NavLink>
            </div>
            <div className={classes.close} onClick={props.onClose}>
              <CloseIcon style={{ fontSize: "2rem", color: "white" }} />
            </div>
            <div className={classes.logout} onClick={logout}>
              <LogoutIcon className={classes.icon} />
              <p>Cerrar sesión</p>
            </div>
          </div>
        </Overlay>,
        portalElement
      )}
    </>
  );
};

export default Sidebar;
