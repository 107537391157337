import { useContext, useRef, useState } from "react";
import classes from "./Login.module.css";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useStorage";
import { toastError } from "../../lib/toast";
import usePageTitle from "../../hooks/usePageTitle";
import Card from "./../../components/ui/Card/Card";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import useUserData from "../../hooks/Auth/useUserData";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";

const Login = () => {
  useLockBodyScroll();

  usePageTitle("B2B - Motorvinilo - Inicio de sesión");

  const { loginHandler } = useUserData();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [error, setError] = useState(false);

  const onSubmit = async (data) => {
    try {
      await loginHandler(data.email, data.password);
      setError(false);
    } catch (error) {
      toastError(`Se ha producido un error`);
      setError(true);
    }
  };

  return (
    <div className={classes.page}>
      <div className={classes.container}>
        <img
          src="motorvinilo-logo-login.png"
          alt="MotorVinilo Login B2B"
          className={classes.logo}
        />
        <div className={classes.box}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.inputs}>
              <div className={classes.input}>
                <label>Email:</label>
                <input
                  type="email"
                  placeholder="Email"
                  className={classes.field}
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className={classes.error}>
                    El campo email és obligatorio
                  </span>
                )}
              </div>
              <div className={classes.input}>
                <label>Contraseña:</label>
                <input
                  type="password"
                  placeholder="Password"
                  className={classes.field}
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <span className={classes.error}>
                    El campo contraseña és obligatorio
                  </span>
                )}
              </div>
            </div>
            {error && (
              <p className={classes.error}>
                Ha ocurrido un error al iniciar sesión revisa la contraseña y el
                email introducidos. Si el problema persiste contacta con
                MotorVinilo.
              </p>
            )}
            <div className={classes.btns}>
              <input
                type="submit"
                value="Iniciar sesión"
                className={`btn-secondary ${classes.btn}`}
              />
            </div>

            <div className={classes.btns}>
              <Link className={classes.link} to="public/forgot/password">
                ¿Has olvidado tu contraseña?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
