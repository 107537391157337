import { useState } from "react";
import { useQuery } from "react-query";
import Select from "react-select";
import LoadingIcon from "../../../../components/ui/icons/LoadingIcon";
import {
  brandsList,
  generationsList,
  modelsList,
  yearsList,
} from "../../../../lib/generations";
import { Tooltip } from "react-tippy";
import classes from "./GenerationSelector.module.css";

const GenerationSelector = ({ token, onCancel, onSelect }) => {
  // Recupero marcas, preparo options, state y defino handler
  const brandsQuery = useQuery(["brands", { token }], brandsList);
  const [brand, setBrand] = useState(null);
  const brandsOptions = [];

  brandsQuery.data?.map((brand) =>
    brandsOptions.push({ label: brand.name, value: brand.id })
  );

  const brandHandler = (brand) => setBrand(brand);

  // Recupero modelo, preparo options, state y defino handler
  const brandValue = brand?.value;
  const modelsQuery = useQuery(["models", { token, brand }], modelsList, {
    enabled: !!brandValue,
  });
  const [model, setModel] = useState(null);
  const modelsOptions = [];

  modelsQuery.data?.map((model) =>
    modelsOptions.push({ label: model.name, value: model.id })
  );

  const modelHandler = (model) => setModel(model);

  // Recupero años, preparo options, state y defino handler
  const modelValue = model?.value;
  const yearsQuery = useQuery(
    ["years", { token, selectedModel: model }],
    yearsList,
    {
      enabled: !!modelValue,
    }
  );
  const [year, setYear] = useState(null);
  const yearsOptions = [];

  yearsQuery.data?.map((year) =>
    yearsOptions.push({ label: year, value: year })
  );

  const yearHandler = (year) => setYear(year);

  // Recupero generaciones, preparo options, state y defino handler
  const generationsQuery = useQuery(
    ["generations", { token, model, year }],
    generationsList,
    {
      enabled: !!model && !!year,
    }
  );
  const [generation, setGeneration] = useState(null);
  const generationsOptions = [];

  generationsQuery.data?.map((generation) =>
    generationsOptions.push({ label: generation.name, value: generation.id })
  );

  const generationHandler = (generation) => setGeneration(generation);

  // Envia detalles al smart component
  const onSelectHander = () => {
    if (!!generation) {
      onSelect({
        brand: brand,
        model: model,
        year: year,
        generation: generation,
      });

      onCancel();
    }
  };

  if (brandsQuery.isLoading) {
    return (
      <div className={classes.isLoading}>
        <LoadingIcon />
      </div>
    );
  }

  return (
    <div className={classes.content}>
      <div className={classes.selects}>
        {/* Marca */}
        {!brandsQuery.isLoading && !brandsQuery.isError && (
          <div className={classes.select}>
            <label>Marca:</label>
            <Select
              options={brandsOptions}
              onChange={brandHandler}
              placeholder="Selecciona una marca"
              noOptionsMessage={() => `Sin resultados`}
            />
          </div>
        )}

        {/* Modelo */}
        <div className={classes.select}>
          {!modelsQuery.isLoading && !modelsQuery.isError && brand && (
            <>
              <label>Modelo:</label>
              <Select
                options={modelsOptions}
                onChange={modelHandler}
                placeholder="Selecciona un modelo"
                noOptionsMessage={() => `Sin resultados`}
              />
            </>
          )}
        </div>

        {/* Año */}
        <div className={classes.select}>
          {!yearsQuery.isLoading && !yearsQuery.isError && model && (
            <>
              <label>Año:</label>
              <Select
                options={yearsOptions}
                onChange={yearHandler}
                placeholder="Selecciona un año"
                noOptionsMessage={() => `Sin resultados`}
              />
            </>
          )}
        </div>

        {/* Generación */}
        <div className={classes.select}>
          {!generationsQuery.isLoading && !generationsQuery.isError && year && (
            <>
              <label>Generación:</label>
              <Select
                options={generationsOptions}
                onChange={generationHandler}
                placeholder="Selecciona una generación"
                noOptionsMessage={() => `Sin resultados`}
              />
            </>
          )}
        </div>
      </div>

      <div className={classes.buttons}>
        <input
          type="button"
          value="Cancelar"
          onClick={onCancel}
          className={`${classes.btn} btn-cancel`}
        />
        <Tooltip
          title="Selecciona una generación primero"
          position="bottom"
          trigger="click"
          arrow="true"
          disabled={!!generation}
          className={classes.btn}>
          <input
            type="button"
            value="Seleccionar"
            onClick={onSelectHander}
            className={`${!!generation ? "btn-active" : "btn-disabled"} ${
              classes.btnTooltip
            }`}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default GenerationSelector;
