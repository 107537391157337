import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const getCartProducts = async (token) => {
  // const [key, { token }] = queryKey;

  // console.log(token)

  const result = await redaxios.get(`${domain}/api/cart/index`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const cartList = async (token) => {
  const result = await redaxios.get(`${domain}/api/cart/index`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const saveCartList = async (token) => {
  const result = await redaxios.get(`${domain}/api/cart/save`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data;
};

export const addCartProducts = async (values) => {
  // console.log(values);

  const result = await redaxios.post(
    `${domain}/api/cart/store`,
    [values.values],
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${values.token}`,
      },
    }
  );

  return result.data;
};

export const removeCartProduct = async (params) => {
  const result = await redaxios.get(
    `${domain}/api/cart/destroy/${params.service}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${params.token}`,
      },
    }
  );

  return result.data;
};

export const cleanCart = async (token) => {
  const result = await redaxios.get(`${domain}/api/cart/clean`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};
