import { Outlet } from "react-router-dom";
import useUserData from "../../hooks/Auth/useUserData";
import Login from "../../pages/Auth/Login";
import Unauthorized from "../../pages/Unauthorized";
import useUserPermissions from "./../../hooks/Auth/useUserPermissions";

const RequireAuth = ({ allowedRoles }) => {
  const { token } = useUserData();
  const { userCan } = useUserPermissions();

  if (token) {
    if (userCan(allowedRoles)) {
      return <Outlet />;
    } else {
      return <Unauthorized />;
    }
  } else {
    return <Login />;
  }
};

export default RequireAuth;
