import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const getCompaniesList = async (token) => {
  const result = await redaxios.get(`${domain}/api/company`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data.data;
};

export const createCompany = async (token, company) => {
  console.log(company);
  const result = await redaxios.post(`${domain}/api/company/create`, company, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data.data;
};

export const getCompaniesSelectList = async (token) => {
  const result = await redaxios.get(`${domain}/api/company/list`, {
    headers:{Accept: 'application/json', Authorization: `Bearer ${token}`}
  })

  return result.data.data;
}
