import classes from "./Cart.module.css";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import ProductPrice from "../../../components/Quotes/ProductPrice";
import Card from "../../../components/ui/Card/Card";
import Error from "../../../components/ui/Error/Error";
import Loading from "../../../components/ui/Loading/Loading";
import Context from "../../../context/SessionContext";
import { getCartProducts, removeCartProduct } from "../../../lib/cart";
import {
  createOrder,
  saveQuote,
  storeAndCreateOrder,
} from "../../../lib/quote";
import { getSettings } from "../../../lib/settings";
import { toastError, success } from "../../../lib/toast";
import TrashIcon from "../../../components/ui/icons/TrashIcon";
import usePageTitle from "../../../hooks/usePageTitle";
import Breadcrumbs from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import MemoizedDetailRow from "../../../components/DetailRow/DetailRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { price } from "../../../lib/price";
import { useCartQuery } from "../../../lib/queries";
import { Tooltip } from "react-tippy";
import InformationIcon from "../../../components/ui/icons/InformationIcon";
import StartIcon from "../../../components/ui/icons/StarIcon";
import usePoints from "../../../hooks/usePoints";
import PointsIndicador from "../../../components/PointsIndicator";
import useUserData from "../../../hooks/Auth/useUserData";
import useCartProductsList from "../../../hooks/Cart/useCartProductsList";
import useSettings from "../../../hooks/Settings/useSettings";
import useQuoteService from "../../../hooks/Quotes/useQuoteService";

const UserCart = ({ title }) => {
  usePageTitle(title);

  // const session = useContext(Context);
  // const token = session.storedValue.token;

  const { token } = useUserData();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // Recupera listado de productos
  const { cartProductsListQuery } = useCartProductsList();

  const { saveCart, saveAndTransform } = useQuoteService();

  // Recupera vehículo del presupuesto
  // const settingsQuery = useQuery(["settings", { token }], getSettings);
  const { settingsQuery } = useSettings();

  // Mutators para guardar el presupusto o eliminar productos
  // const saveQuoteMutation = useMutation(saveQuote, {
  //   onSuccess: () => {
  //     success();
  //     queryClient.invalidateQueries("settings");
  //     queryClient.invalidateQueries("cartList");
  //     queryClient.invalidateQueries("userPoints");
  //     navigate("/quotes");
  //   },
  //   onError: () => toastError(),
  // });

  const saveAndOrderMutation = useMutation(storeAndCreateOrder, {
    onSuccess: () => {
      success();
      queryClient.invalidateQueries("settings");
      queryClient.invalidateQueries("cartList");
      queryClient.invalidateQueries("userPoints");
      navigate("/quotes");
    },
    onError: () => toastError(),
  });

  const removeServiceMutation = useMutation(removeCartProduct, {
    onSuccess: () => {
      success();
      queryClient.invalidateQueries("cartList");
    },
    onError: () => toastError(),
  });

  // Nombre del cliente del presupuesto
  const [quoteName, setQuoteName] = useState("");
  const quoteNameHandler = (event) => {
    setQuoteName(event.target.value);
  };

  useEffect(() => {
    if (quoteName.length > 0) setIsClientNameError(false);
  }, [quoteName]);

  // Control error si el nombre del cliente del presupuesto esta completado
  const [isClientNameError, setIsClientNameError] = useState(false);

  // Comentatios del presupuesto
  const [notes, setNotes] = useState("");

  const notesHandler = (event) => {
    setNotes(event.target.value);
  };

  const saveQuoteMutationHandler = (quote) => {
    saveCart.mutate({
      token,
      quote,
      generation: settingsQuery.data.generation_id,
    });
    // saveQuoteMutation.mutate({
    //   token,
    //   quote,
    //   generation: settingsQuery.data.generation_id,
    // });
  };

  const prepareToSave = () => {
    const quote = {
      reference: quoteName,
      notes: notes,
      generation_id: settingsQuery.data.generation_id,
      generation_name: `${settingsQuery.data.brand_name} ${settingsQuery.data.generation_name}`,
      rows: [],
    };

    cartProductsListQuery.data.map((product) => {
      quote.rows.push({
        product: product.product_name,
        product_id: product.product_id,
        material: product.material_name,
        material_id: product.material_id,
        units: product.units,
        price: product.price,
        totalLine: product.units * product.price,
        discount: +product.discount,
        finalPrice:
          product.units * product.price * (1 - +product.discount / 100),
      });
    });

    return quote;
  };

  const scrollToTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Guardar el presupuesto
  const saveHandler = () => {
    if (quoteName.length == 0) {
      setIsClientNameError(true);
      scrollToTopHandler();
    } else {
      const quote = prepareToSave();

      // saveQuote.mutate({
      //   ...quote,
      //   generation: settingsQuery.data.generation_id,
      // });
      saveCart.mutate(quote, {
        onSuccess: (data) => navigate(`/quotes/${data.id}`),
        onError: () => toastError(),
      });

      // saveQuoteMutationHandler(quote);
    }
  };

  const saveAndOrderHandler = () => {
    // alert(`Mutator save and create`);

    if (quoteName.length == 0) {
      setIsClientNameError(true);
      scrollToTopHandler();
    } else {
      saveAndTransform.mutate({ onSuccess: (data) => console.log(data) });

      // const quote = prepareToSave();

      // saveAndOrderMutation.mutate({
      //   quote,
      //   token,
      //   generation: settingsQuery.data.generation_id,
      // });
    }
  };

  // Elimina un servicio del presupuesto
  const removeServiceHandler = (service) => {
    // alert(service);

    removeServiceMutation.mutate({
      token,
      service,
    });
  };

  // Nuevo presupuesto
  const newQuotehandler = () => {
    navigate(`/quotes/new`);
  };

  // Calcula subtotal, descuentos e impuestos
  const memoizedSubTotal = useMemo(() => {
    return cartProductsListQuery?.data?.reduce(
      (partialSum, row) => partialSum + +row.price * row.units,
      0
    );
  }, [cartProductsListQuery?.data]);

  let discount = 0;

  if (cartProductsListQuery?.data?.length) {
    if (cartProductsListQuery.data[0].discount != 0) {
      discount =
        memoizedSubTotal * (cartProductsListQuery?.data[0].discount / 100);
    }
  }

  const vat = (memoizedSubTotal - discount) * 0.21;

  const total = memoizedSubTotal - discount + vat;

  const points = usePoints(total);

  if (cartProductsListQuery.isLoading || settingsQuery.isLoading) {
    return <Loading />;
  }

  if (cartProductsListQuery.isError || settingsQuery.isError) {
    return <Error />;
  }

  if (cartProductsListQuery?.data.length == 0 || !settingsQuery.data) {
    return (
      <div className={classes.empty}>
        <div className={classes.content}>
          <h4>Crea un nuevo presupuesto para empezar</h4>
          <input
            type="button"
            value="Crear presupuesto"
            onClick={newQuotehandler}
            className={`btn-active ${classes.btn} btn-responsive`}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <div className={classes.header}>
        <h1>Carrito</h1>
        {/* <Chip status={quoteQuery?.data?.status} /> */}
      </div>

      <div className={classes.sections}>
        <p className={classes.title}>Resumen del presupuesto</p>
        <Card>
          <div className={classes.abstract}>
            <div className={classes.abstractDetails}>
              <p className={classes.bold}>Cliente:</p>
              <div className={classes.client}>
                <input
                  type="text"
                  placeholder="Nombre del cliente"
                  value={quoteName}
                  onChange={quoteNameHandler}
                  className={
                    isClientNameError ? classes.error : classes.defaultInput
                  }
                />
                <Tooltip
                  title="Especifique el nombre de su cliente en el presupuesto"
                  position="top"
                  trigger="click">
                  <InformationIcon />
                </Tooltip>
              </div>
              <p className={classes.bold}>Vehículo:</p>
              <p
                className={
                  classes.content
                }>{`${settingsQuery.data.brand_name} ${settingsQuery.data.generation_name}`}</p>

              <p className={classes.bold}>Total:</p>
              <div className={classes.finalPrice}>
                <p className={`${classes.content} ${classes.price}`}>
                  {price(total)}
                </p>
                <p>(IVA incluido)</p>
              </div>
            </div>
          </div>
        </Card>

        <p className={classes.title}>Detalle</p>
        <Card>
          <div className={classes.details}>
            {/* Listado de productos/servicios presupuestados */}
            <div className={classes.details}>
              <MemoizedDetailRow
                rows={cartProductsListQuery.data}
                delete={true}
                onDelete={removeServiceHandler}
              />
            </div>

            {/* Subtotal, descuento, IVA y total */}
            <div className={classes.total}>
              <p className={classes.bold}>Subtotal:</p>
              <p>{price(memoizedSubTotal)}</p>

              <p className={classes.bold}>Descuento:</p>
              <p>{price(discount)}</p>

              <p className={classes.bold}>IVA:</p>
              <p>{price(vat)}</p>

              <p className={classes.bold}>Total:</p>
              <p>{price(total)}</p>
            </div>
          </div>
        </Card>

        <Card>
          <div className={classes.points}>
            <p>Con este pedido acumulas:</p>
            <PointsIndicador points={points} />
            {/* <div className={classes.value}>
              <StartIcon className={classes.icon} /> <p>{points} puntos</p>
            </div> */}
          </div>
        </Card>

        {/* Notas del cliente */}
        <div className={classes.notes}>
          <p className={classes.title}>
            Notas (puedes indicar aquí comentarios o solicitudes especiales del
            cliente)
          </p>
          <div className={classes.notesBox}>
            <textarea
              className={classes.note}
              name=""
              id=""
              cols="30"
              rows="10"
              onChange={notesHandler}></textarea>
          </div>
        </div>
      </div>

      {/* Botones para volver atrás y hacer pedido */}
      <div className={classes.footer}>
        <Link
          to="/quotes/new"
          style={{ textDecoration: "none", color: "black", fontSize: ".9rem" }}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: "5px" }}
            className={classes.footerIcon}
          />
          Añadir más productos al carrito
        </Link>

        <div className={classes.btnHolder}>
          <input
            type="button"
            value="Guardar presupuesto"
            className={`btn-secondary ${classes.saveButton}`}
            onClick={saveHandler}
          />
          <Tooltip
            title="Guarda el presupuesto para realizar el pedido más adelante."
            position="bottom"
            trigger="click">
            <InformationIcon />
          </Tooltip>
        </div>

        <div className={classes.btnHolder}>
          <input
            type="button"
            value="Realizar pedido"
            className={`btn-active ${classes.saveButton}`}
            onClick={saveAndOrderHandler}
          />
          <Tooltip
            title="Al realizar pedido este se envía a nuestro centro MotorVinilo para gestionar y coordinar una fecha para hacerlo."
            position="bottom"
            trigger="click">
            <InformationIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default UserCart;
