import { useMutation, useQuery } from "react-query";
import { createOrder, getQuote } from "../../lib/quote";
import { success, toastError } from "../../lib/toast";
import useUserData from "../Auth/useUserData";

const useQuote = (quoteId) => {
  const { token } = useUserData();

  const quoteDetails = useQuery(
    ["quoteDetails", quoteId],
    () => {
      const details = getQuote(token, quoteId);
      return details;
    },
    {
      onError: () => toastError(),
    }
  );

  const order = useMutation(
    async (quote) => {
      const result = await createOrder(token, quote);
      return result;
    },
    {
      onSuccess: () => success(),
      onError: () => toastError(),
    }
  );

  return { quoteDetails, order };
};

export default useQuote;
