import classes from "./Color.module.css";

const Color = (props) => {
  const onSelect = () => props.onSelect(props.color);

  return (
    <div
      className={`${classes.color} ${
        props.selected == props.color ? classes.selected : null
      }`}
      onClick={onSelect}>
      <main>
        <p>{props.color}</p>
        <div className={`${classes.sample} ${props.className}`}></div>
      </main>
    </div>
  );
};

export default Color;
