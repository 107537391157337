import { useQuery } from "react-query";
import { getOrderDetails } from "../../lib/order";
import { toastError } from "../../lib/toast";
import useUserData from "../Auth/useUserData";

const useOrder = (orderId) => {
  const { token } = useUserData();

  const order = useQuery(
    ["order", token, orderId],
    () => {
      const result = getOrderDetails(token, orderId);

      return result;
    },
    {
      onError: () => toastError(),
    }
  );

  return { order };
};

export default useOrder;
