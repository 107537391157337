import { useQuery } from "react-query";
import { getQuotes } from "../../lib/quote";
import { toastError } from "../../lib/toast";
import useUserData from "../Auth/useUserData";

const useQuotes = () => {
  const { token } = useUserData();

  const quotesList = useQuery(
    ["quotesList", token],
    () => {
      const response = getQuotes(token);
      return response;
    },
    {
      onError: () => toastError(),
    }
  );

  return { quotesList };
};

export default useQuotes;
