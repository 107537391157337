import classes from "./OrderDetails.module.css";
import { useMemo } from "react";
import Error from "../../../components/ui/Error/Error";
import Loading from "../../../components/ui/Loading/Loading";
import useOrder from "../../../hooks/Order/useOrder";
import { Link, useParams } from "react-router-dom";
import MemoizedDetailRow from "../../../components/DetailRow/DetailRow";
import Card from "../../../components/ui/Card/Card";
import Context from "../../../context/SessionContext";
import usePageTitle from "../../../hooks/usePageTitle";
import { price } from "../../../lib/price";
import { createOrder, getQuote } from "../../../lib/quote";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import Chip from "../../../components/Quotes/status/Chip";
import { success, toastError } from "../../../lib/toast";

const OrderDetails = ({ title }) => {
  usePageTitle(title);
  const { orderId } = useParams();

  const { order } = useOrder(orderId);

  const memoizedSubTotal = useMemo(() => {
    return order?.data?.rows.reduce(
      (partialSum, row) => partialSum + +row.price * row.units,
      0
    );
  }, [order?.data?.rows]);

  const discount = memoizedSubTotal * (order?.data?.rows[0].discount / 100);

  const vat = (memoizedSubTotal - discount) * 0.21;

  const total = memoizedSubTotal - discount + vat;

  if (order.isLoading) {
    return <Loading />;
  }

  if (order.isError) {
    return <Error />;
  }

  // return null;

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <div className={classes.header}>
        <h1>Presupuesto: {order?.data?.reference}</h1>
        <Chip status={order?.data?.status} />
      </div>

      <div className={classes.sections}>
        <p className={classes.title}>Resumen</p>
        <Card>
          <div className={classes.abstract}>
            <div className={classes.abstractDetails}>
              <p className={classes.bold}>Vehículo:</p>
              <p className={classes.content}>{order?.data?.generation_name}</p>

              <p className={classes.bold}>Fecha:</p>
              <p className={classes.content}>{order?.data?.created_at}</p>

              <p className={classes.bold}>Id presupuesto:</p>
              <p className={classes.content}>#{order?.data?.id}</p>

              <p className={classes.bold}>Total IVA incluido:</p>
              <div className={classes.finalPrice}>
                <p className={`${classes.content} ${classes.price}`}>
                  {price(total)}
                </p>
                <p>(IVA incluido)</p>
              </div>
            </div>
          </div>
        </Card>

        <p className={classes.title}>Detalle</p>
        <Card>
          <div className={classes.details}>
            {/* Listado de productos/servicios presupuestados */}
            <div className={classes.details}>
              <MemoizedDetailRow rows={order?.data?.rows} />
            </div>

            {/* Subtotal, descuento, IVA y total */}
            <div className={classes.total}>
              <p className={classes.bold}>Subtotal:</p>
              <p>{price(memoizedSubTotal)}</p>

              <p className={classes.bold}>Descuento:</p>
              <p>{price(discount)}</p>

              <p className={classes.bold}>IVA:</p>
              <p>{price(vat)}</p>

              <p className={classes.bold}>Total:</p>
              <p>{price(total)}</p>
            </div>
          </div>
        </Card>

        {order.data?.notes && (
          <>
            <p className={classes.title}>Notas</p>
            <Card>
              <p>{order.data?.notes}</p>
            </Card>
          </>
        )}
      </div>

      {/* Botones para volver atrás y hacer pedido */}
      <div className={classes.footer}>
        <Link
          to="/orders"
          style={{ textDecoration: "none", color: "black", fontSize: ".9rem" }}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            style={{ marginRight: "5px" }}
            className={classes.footerIcon}
          />
          Todos mis pedidos
        </Link>
        {/* <input
          type="button"
          value={`${
            order?.data?.status != 2 ? "Realizar pedido" : "Pedido procesado"
          }`}
          className={`${classes.footerLink} ${
            order?.data?.status != 2 ? "btn-active" : "btn-disabled"
          }`}
          disabled={order?.data?.status == 2}
          // onClick={transformToOrderHandler}
        /> */}
      </div>
    </div>
  );
};

export default OrderDetails;
