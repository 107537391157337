import classes from "./OrdersList.module.css";
import Error from "../../../components/ui/Error/Error";
import Loading from "../../../components/ui/Loading/Loading";
import useOrders from "../../../hooks/Order/useOrders";
import Breadcrumbs from "../../../components/ui/Breadcrumbs/Breadcrumbs";
import ScrollUp from "../../../components/ui/ScrollUp/ScrollUp";
import Header from "../../../components/Header/Header";
import Card from "../../../components/ui/Card/Card";
import QuotePreview from "../../../components/Quotes/list/QuotePreview";
import { useNavigate } from "react-router-dom";

const OrdersList = () => {
  const { orders } = useOrders();

  const navigate = useNavigate();

  const clickHandler = (orderId) => {
    navigate(`/orders/${orderId}`);
  }

  if (orders.isLoading) {
    return <Loading />;
  }

  if (orders.isError) {
    return <Error />;
  }

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <ScrollUp />

      <Header>
        <h1>Pedidos</h1>
      </Header>

      <div className={classes.orders}>
        {orders.data?.map((order) => (
          <Card key={order.id}>
            <QuotePreview onDetails={clickHandler} quote={order} />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default OrdersList;
