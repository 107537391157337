import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactGA from "react-ga";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import Nav from "./components/ui/nav/Nav.js";
import Footer from "./components/ui/Footer/Footer";
import Context from "./context/SessionContext.js";
import { useContext } from "react";
import useUserPermissions from "./hooks/Auth/useUserPermissions.js";
import CreateUser from "./pages/User/CreateUser/index.js";
import Layout from "./components/Layout/index.js";
import QuotesList from "./pages/Quotes/QuoteList/index.js";
import QuoteDetails from "./pages/Quotes/QuoteDetails/index.js";
import CreateQuote from "./pages/Quotes/CreateQuote/CreateQuote.js";
import OrdersList from "./pages/Orders/OrdersList/index.js";
import UserCart from "./pages/Cart/CartList/index.js";
import Gallery from "./pages/Gallery/index.js";
import Contact from "./pages/Contact/index.js";
import OrderDetails from "./pages/Orders/OrderDetails/index.js";
import Home from "./pages/Home/Home.js";
import RequireAuth from "./components/RequireAurh/index.js";
import UserManagement from "./pages/User/UserManagement/index.js";
import UpdatePassword from "./pages/User/UpdatePassword/index.js";
import ScrollToTop from "./components/ScrollToTop/index.js";
import UpdateProfile from "./pages/User/UpdateProfile/index.js";
import ForgotPassword from "./pages/User/ForgotPassword/index.js";
import CompaniesList from "./pages/Company/CompaniesList/index.js";
import CreateCompany from "./pages/Company/CreateCompany/index.js";

const App = () => {
  const session = useContext(Context);
  const { userCan } = useUserPermissions();

  // Google Analytics
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <Nav />
      <ScrollToTop>
        <Routes>
          {/* Rutes publiques */}
          <Route path="public" element={<Layout />}>
            <Route
              path="user/forgot/password/:refreshToken"
              element={
                <UpdatePassword title="B2B - MotorVinilo - Actualizar contraseña" />
              }
            />

            {/* Recuper contrassenya */}
            <Route
              path="forgot/password"
              element={
                <ForgotPassword title="MotorVinilo Pro - Recuperar contraseña" />
              }
            />
          </Route>

          {/* Rutes privades */}

          {/* Permís per actualizar usuaris */}
          <Route element={<RequireAuth allowedRoles={["user.update"]} />}>
            <Route
              path="/"
              element={<Home title="B2B - MotorVinilo - Inicio" />}
            />

            <Route
              path="/user/create"
              element={
                <CreateUser title="B2B - MotorVinilo - Registar usuario" />
              }
            />

            {/* Permís per editar usuaris */}
            <Route element={<RequireAuth allowedRoles={["user.update"]} />}>
              <Route path="/" element={<Home title="B2B - MotorVinilo" />} />

              <Route
                path="/quotes"
                element={
                  <QuotesList title="B2B - MotorVinilo - Presupuestos" />
                }
              />

              <Route
                path="/quotes/new"
                element={
                  <CreateQuote title="B2B - MotorVinilo - Nuevo presupuesto" />
                }
              />
              <Route
                path="/quotes/:quoteId"
                element={
                  <QuoteDetails title="B2B - MotorVinilo - Detalle de presupuesto" />
                }
              />

              <Route
                path="/cart"
                element={<UserCart title="B2B - MotorVinilo - Carrito" />}
              />
              <Route
                path="/orders"
                element={<OrdersList title="B2B - MotorVinilo - Pedidos" />}
              />

              <Route
                path="/orders/:orderId"
                element={
                  <OrderDetails title="B2B - MotorVinilo - Detalle del pedido" />
                }
              />

              <Route
                path="/showroom"
                element={
                  <Gallery title="B2B - MotorVinilo - Galería de imágenes" />
                }
              />

              <Route
                path="/services"
                element={
                  <Gallery title="B2B - MotorVinilo - Servicios destacados" />
                }
              />

              <Route
                path="/user"
                element={<UserManagement title="B2B - MotorVinilo - Perfil" />}
              />

              <Route
                path="/user/edit"
                element={
                  <UpdateProfile title="MotorVinilo Pro - Actualizar perfil" />
                }
              />

              <Route
                path="/contact"
                element={<Contact title="B2B - MotorVinilo - Contacto" />}
              />
            </Route>

            {/* Permís per cambiar d'empresa */}
            <Route element={<RequireAuth allowedRoles={["account.swap"]} />}>
              <Route path="/companies" element={<CompaniesList />} />
              <Route path="/companies/new" element={<CreateCompany />} />
            </Route>
          </Route>
        </Routes>
      </ScrollToTop>

      <Footer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    // <>
    //   <QueryClientProvider client={queryClient}>
    //     <Toaster />
    //     <Nav />
    //     {!session?.storedValue.token && <Login />}
    //     {session?.storedValue.token && userCan("user.update") && (
    //       <>
    //         <CartContextProvider>
    //           <Routes>
    //             {routes.map((route, key) => {
    //               return (
    //                 <Route
    //                   key={key}
    //                   path={route.path}
    //                   element={() => {
    //                     return userCan(route.permission) ? (
    //                       <CreateUser title={route.title} />
    //                     ) : (
    //                       <Login />
    //                     );
    //                   }}
    //                 />
    //               );
    //             })}
    //           </Routes>
    //         </CartContextProvider>
    //         <Footer />
    //         <ReactQueryDevtools initialIsOpen={false} />
    //       </>
    //     )}
    //   </QueryClientProvider>
    // </>
  );
};

export default App;
