import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const products = async ({ queryKey }) => {
  const [key, { token, generationId }] = queryKey;

  const result = await redaxios.get(
    `${domain}/api/quote/products/${generationId}`,
    {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    }
  );
  return result.data;
};

export const productPrice = async ({ queryKey }) => {
  const [key, { ...params }] = queryKey;

  const result = await redaxios.post(
    `${domain}/api/quote/unitprice`,
    {
      generationId: params.generation,
      materialId: params.material,
      productId: params.product,
    },
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${params.token}`,
      },
    }
  );

  return result.data;
};
