import { useEffect, useState } from "react";
import classes from "./Filter.module.css";
import useDebounce from "../../../hooks/useDebounce";
import SearchIcon from "../icons/SearchIcon";

const Filter = (props) => {
  const [filter, setFilter] = useState("");

  const debouncedFilter = useDebounce(filter);

  const setFilterHandler = (event) => setFilter(event.target.value);

  useEffect(() => {
    props.onFilter(debouncedFilter);
  }, [debouncedFilter]);

  return (
    <div className={`${classes.box} ${props.box}`}>
      <SearchIcon className={classes.icon} />
      <input
        type="text"
        name=""
        id=""
        onChange={setFilterHandler}
        placeholder={props.placeholder}
        className={`${classes.input} ${props.className}`}
      />
    </div>
  );
};

export default Filter;
