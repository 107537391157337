import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const contactEmail = async (token, email) => {
  const response = await redaxios.post(
    `${domain}/api/email/send`,
    {
      mail: email,
    },
    {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    }
  );

  return response.data;
};
