import { useMutation } from "react-query";
import { contactEmail } from "../../lib/email";
import { success, toastError } from "../../lib/toast";
import useUserData from "../Auth/useUserData";

const useContactForm = () => {
  const { token } = useUserData();

  const addMessageForm = useMutation(
    async (message) => {
      const result = contactEmail(token, message);
      return result;
    },
    {
      onSuccess: () =>
        success(`Hemos recibido su petición de contacto correctamente`),
      onError: () =>
        toastError(`Se ha producido un error, por favor inténtalo más tarde`),
    }
  );

  return { addMessageForm };
};

export default useContactForm;
