import { useMutation } from "react-query";
import { updateUserPassword } from "../../lib/users";
import { success, toastError } from "../../lib/toast";

const useUpdatePassword = () => {
  const updatePassword = useMutation(
    async (data) => {
      const result = await updateUserPassword(data);
      return result;
    },
    {
      onSuccess: () => success(),
      onError: () => toastError(),
    }
  );

  return { updatePassword };
};

export default useUpdatePassword;
