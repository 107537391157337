import classes from "./ForgotPassword.module.css";
import MainContainer from "../../../components/ui/MainContainer";
import usePageTitle from "../../../hooks/usePageTitle";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import redaxios from "redaxios";

const ForgotPassword = ({ title }) => {
  usePageTitle(title);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const cancelHandler = () => navigate("/");

  const onSubmit = async (data) => {
    console.log(data);
    const result = await redaxios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/forgot`,
      { email: data.email }
    );

    return result.data;
  };

  return (
    <MainContainer>
      <h1>Registrar usuario</h1>

      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.formBox}>
          <div className={classes.input}>
            <label className={classes.label}>Email:</label>
            <input
              type="email"
              name="email"
              {...register("email", { required: true })}
            />
            {errors.email && (
              <span className={classes.errorField}>
                El campo email es obligatorio
              </span>
            )}
          </div>
        </div>

        <div className={classes.buttons}>
          <button
            className={`btn-cancel ${classes.btn}`}
            onClick={cancelHandler}>
            Cancelar
          </button>
          <button type="submit" className={`btn-active ${classes.btn}`}>
            Enviar
          </button>
        </div>
      </form>
    </MainContainer>
  );
};

export default ForgotPassword;
