import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const materials = async ({queryKey}) => {

  const [key, {token}] = queryKey;

  const result = await redaxios.get(`${domain}/api/quote/materials`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });
  
  return result.data.data;
};
