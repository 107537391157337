import { useQuery } from "react-query";
import redaxios from "redaxios";
import useUserData from "./Auth/useUserData";

const useUserPoints = () => {
  const domain = process.env.REACT_APP_BACKEND_URL;

  const { token } = useUserData();

  const pointsQuery = useQuery(["userPoints", token], async () => {
    const response = await redaxios.get(`${domain}/api/user/points`, {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    });

    return response.data;
  });

  return pointsQuery;
};

export default useUserPoints;
