import classes from "./Nav.module.css";
import CartIcon from "../icons/CartIcon";
import { useCartQuery } from "../../../lib/queries";
import { useContext } from "react";
import Context from "../../../context/SessionContext";
import useCartProductsList from "../../../hooks/Cart/useCartProductsList";

const ShoppingCart = () => {
  const { cartProductsListQuery } = useCartProductsList();

  return (
    <CartIcon
      className={`${classes.cart} ${
        cartProductsListQuery.data?.length > 0 ? classes.full : classes.empty
      }`}
    />
  );
};

export default ShoppingCart;
