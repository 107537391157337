import classes from "./UnitsSelector.module.css";

const UnitsSelector = (props) => {
  return (
    <div className={classes.units}>
      <p>Unidades: {props.value}</p>
      <input
        type="button"
        value="-"
        onClick={props.onRemove}
        disabled={!props.isActive || props.value == 0}
      />
      <input
        type="button"
        value="+"
        onClick={props.onAdd}
        disabled={!props.isActive}
      />
    </div>
  );
};

export default UnitsSelector;
