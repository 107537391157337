import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const getCompanyUsers = async (token) => {
  const result = await redaxios.get(`${domain}/api/user/list`, {
    headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
  });

  return result.data.data;
};

export const updateUserStatus = async (token, userId) => {
  const result = await redaxios.get(
    `${domain}/api/user/update/status/${userId}`,
    {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    }
  );

  return result.data.data;
};

export const updateUserPassword = async (data) => {
  const result = await redaxios.post(`${domain}/api/update/password`, data, {
    headers: {
      Accept: "application/json",
    },
  });

  return result.data.data;
};
