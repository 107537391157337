import { useQuery } from "react-query";
import { getCompaniesList } from "../../lib/companies";
import useUserData from "../Auth/useUserData";

const useCompanies = () => {
  const { token } = useUserData();

  const companies = useQuery(["companiesList", token], async () => {
    const result = await getCompaniesList(token);
    return result;
  });

  return { companies };
};

export default useCompanies;
