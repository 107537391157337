import classes from "./MaterialSelector.module.css";
import Select from "react-select";

const MaterialSelector = (props) => {
  // Listado de elementos
  // https://react-select.com/styles
  const customStyles = {
    control: (styles) => ({
      ...styles,
      border: "1px solid darkorange",
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: "darkorange",
    }),
  };

  return (
    <Select
      options={props.materials}
      onChange={props.onSelect}
      className={classes.select}
      placeholder="Escoge el color del vinilo"
      styles={customStyles}
    />
  );
};

export default MaterialSelector;
