import { useEffect, useState } from "react";
import usePoints from "../../hooks/usePoints";
import StartIcon from "../ui/icons/StarIcon";
import PointsIndicator from "../../components/PointsIndicator";
import { price } from "./../../lib/price";
import classes from "./ProductPrice.module.css";

const ProductPrice = ({ units, unitPrice, discount }) => {
  const points = usePoints(units * +unitPrice * (1 - discount / 100) * 1.21);

  let priceValue;
  let discountedPrice;

  if (!isNaN(units) && !isNaN(unitPrice)) {
    priceValue = units * +unitPrice;
    discountedPrice = units * +unitPrice * (1 - discount / 100);
  }

  return (
    <>
      <div className={classes.prices}>
        {units > 0 && priceValue && discountedPrice && discount > 0 && (
          <div className={classes.price}>
            <p className={classes.originalPrice}>{`${price(
              priceValue * 1.21
            )}`}</p>

            <div className={classes.priceWithTaxes}>
              <p className={classes.finalPrice}>
                {`${price(+discountedPrice * 1.21)}`}
              </p>
              <p className={classes.taxes}>(IVA incluido)</p>
            </div>
          </div>
        )}

        {units > 0 && priceValue && discountedPrice && discount == 0 && (
          <div className={classes.price}>
            {/* <p className={classes.originalPrice}>{`${priceValue}`}</p> */}
            <p className={classes.finalPrice}>
              {`${price(+discountedPrice * 1.21)}`}
            </p>
            <p className={classes.taxes}>(IVA incluido)</p>
          </div>
        )}

        {/* <p className={isPrice ? classes.originalPrice : ""}>
        {price(units * unitPrice)}
        </p>
      {isPrice && <p className={classes.finalPrice}>{price(discountedPrice)}</p>} */}
      </div>
      {units > 0 && unitPrice > 0 && points && points > 0 && (
        <div className={classes.points}>
          <PointsIndicator points={points} />
          {/* <StartIcon className={classes.star} /> */}
          {/* <p>{points} puntos</p> */}
        </div>
      )}
    </>
  );
};

export default ProductPrice;
