import { useQuery } from "react-query";
import { getCompaniesSelectList } from "../../lib/companies";
import useUserData from "../Auth/useUserData";

const useSelectCompanies = () => {
  const { token } = useUserData();

  const companiesSelectList = useQuery(
    ["companiesSelectList", token],
    async () => {
      const result = await getCompaniesSelectList(token);
      return result;
    }
  );

  return { companiesSelectList };
};

export default useSelectCompanies;
