import redaxios from "redaxios";
import { useMutation } from "react-query";
import { createCompany } from "../../lib/companies";
import useUserData from "../Auth/useUserData";

const useCompany = () => {
  const { token } = useUserData();

  const addCompany = useMutation(async (company) => {
    const result = await createCompany(token, company);
    return result;
  });

  return { addCompany };
};

export default useCompany;
