import Card from "../../../../components/ui/Card/Card";
import MemoizedProduct from "../Product/Product";
import Product from "../Product/Product";
import classes from "./Products.module.css";

const Products = ({
  filter,
  discount,
  token,
  generation,
  materials,
  onAddProducts,
  products,
}) => {
  const onAddProductsHandler = (product) => {
    // console.log(product);
    onAddProducts(product);
    // alert(`priducto añadido`);
  };

  // console.log(filter);

  const productsFiltered =
    filter.trim() == ""
      ? [...products]
      : products.filter((product) => product.name.includes(filter));

  return (
    <div className={classes.products}>
      {productsFiltered.length > 0 &&
        productsFiltered.map((product) => (
          <Card key={product.id} className={classes.card}>
            <MemoizedProduct
              product={product}
              onAddProducts={onAddProductsHandler}
              materials={materials}
              generation={generation}
              token={token}
              discount={discount}
            />
          </Card>
        ))}
    </div>
  );
};

export default Products;
