import { createContext } from "react";
import { login, logout } from "../lib/sessions";
import useLocalStorage from "../hooks/useStorage";

// Crea el contexto
const Context = createContext({});

// Esto lee el contexto y lo devuelve (props.children) para los demàs componentes
export const SessionContextProvider = (props) => {

  const { storedValue, setValue } = useLocalStorage("sessionStatus", {});

  // console.table(storedValue);

  const loginHandler = async (email, password) => {
    const response = await login(email, password);
    setValue(response);
  };

  const logoutHandler = async () => {
    await logout(storedValue.token);
    setValue({});
  }

  return (
    <Context.Provider value={{ storedValue, loginHandler, logoutHandler }}>
      {props.children}
    </Context.Provider>
  );
};

export default Context;
