import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const saveReport = async (token, report) => {

  const response = await redaxios.post(
    `${domain}/api/report/store`,
    { report: report },
    {
      headers: { Accept: "application/json", Authorization: `Bearer ${token}` },
    }
  );

  console.table(response.data);

  return response.data;
};
