import { useContext } from "react";
import Context from "./../../context/SessionContext";
import useUserData from "./useUserData";

const useUserPermissions = () => {
  // const session = useContext(Context);
  // const permissions = session.storedValue.permissions;

  // console.log(permissions);

  const { permissions } = useUserData();

  const userCan = (permission) => {
    return Array.isArray(permission)
      ? permissions.find((userPermission) =>
          permission.includes(userPermission)
        )
      : permissions.includes(permission);
  };

  return { permissions, userCan };
};

export default useUserPermissions;
