import classes from "./CreateUser.module.css";
import { Controller, useForm } from "react-hook-form";
import MainContainer from "../../../components/ui/MainContainer";
import usePageTitle from "../../../hooks/usePageTitle";
import useUsers from "../../../hooks/Auth/useUser";
import { useNavigate } from "react-router-dom";
import CanCreateUsers from "../../../components/Permissions/CanCreateUsers";
import { success } from "../../../lib/toast";
import CanSwapAccount from "../../../components/Permissions/CanSwapAccount";
import Select from "react-select";
import CompaniesSelectList from "../components/CompaniesSelectList";

const CreateUser = ({ title }) => {
  usePageTitle(title);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const cancelHandler = () => navigate("/user");

  const { addUser } = useUsers();

  const onSubmit = (data) => {
    console.log(data);
    addUser.mutate(data, {
      onSuccess: () => {
        success(`Se ha registrado el usuario correctamente`);
        navigate("/user");
      },
    });
  };

  return (
    <MainContainer>
      <CanCreateUsers>
        <h1>Registrar usuario</h1>

        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <div className={classes.formBox}>
            <div className={classes.input}>
              <label className={classes.label}>Nombre:</label>
              <input
                type="text"
                name="name"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className={classes.errorField}>
                  El campo nombre es obligatorio
                </span>
              )}
            </div>

            <div className={classes.input}>
              <label className={classes.label}>Email:</label>
              <input
                type="email"
                name="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span className={classes.errorField}>
                  El campo email es obligatorio
                </span>
              )}
            </div>

            <div className={classes.input}>
              <label className={classes.label}>Contraseña:</label>
              <input
                type="password"
                name="password"
                {...register("password", { required: true })}
              />
              {errors.password && (
                <span className={classes.errorField}>
                  El campo contraseña es obligatorio
                </span>
              )}
            </div>
          </div>

          <CanSwapAccount>
            <div className={classes.input}>
              <label className={classes.label}>Empresa:</label>
              <Controller
                control={control}
                name="company_id"
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <CompaniesSelectList
                    onChange={(data) => onChange(data.value)}
                  />
                )}
              />
              {errors.company_id && (
                <span className={classes.errorField}>
                  El campo empresa es obligatorio
                </span>
              )}
            </div>
          </CanSwapAccount>

          <div className={classes.buttons}>
            <button
              className={`btn-cancel ${classes.btn}`}
              onClick={cancelHandler}
            >
              Cancelar
            </button>
            <button type="submit" className={`btn-active ${classes.btn}`}>
              Enviar
            </button>
          </div>
        </form>
      </CanCreateUsers>
    </MainContainer>
  );
};

export default CreateUser;
