import { useMutation, useQuery, useQueryClient } from "react-query";
import { success, toastError } from "../../lib/toast";
import { getCompanyUsers, updateUserStatus } from "../../lib/users";
import useUserData from "../Auth/useUserData";

const useUsers = () => {
  const { token } = useUserData();

  const queryClient = useQueryClient();

  const usersQuery = useQuery(
    ["companyUsers", token],
    () => {
      const result = getCompanyUsers(token);
      return result;
    },
    {
      onError: () => toastError(),
    }
  );

  const statusHandler = useMutation(
    async (userId) => {
      const result = await updateUserStatus(token, userId);
      return result;
    },
    {
      onSuccess: () => {
        // success(`Se ha actualizado el estado de la cuenta`);
        // Invalidamos la query companyUsers
        queryClient.invalidateQueries("companyUsers");
      },
      onError: () => toastError(),
    }
  );

  return { usersQuery, statusHandler };
};

export default useUsers;
