import useSelectCompanies from "../../../../hooks/Company/useSelectCompanies";
import Select from "react-select";

const CompaniesSelectList = (props) => {
  const {
    companiesSelectList: { data, isLoading, isError },
  } = useSelectCompanies();

  if (isLoading) {
    return <p>Loading</p>;
  }

  if (isError) {
    return <p>error</p>;
  }

  return (
    <Select placeholder={`Selecciona una empresa`} options={data} {...props} />
  );
};

export default CompaniesSelectList;
