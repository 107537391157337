import { useNavigate } from "react-router-dom";
import DoneIcon from "../../ui/icons/DoneIcon";
import RightArrowIcon from "../../ui/icons/RightArrowIcon";
import SaveIcon from "../../ui/icons/SaveIcon";
import classes from "./QuotePreview.module.css";

const QuotePreview = (props) => {
  const navigate = useNavigate();

  const detailsHandler = () => {
    // navigate(`/quotes/${props.quote.id}`);
    props.onDetails(props.quote.id);
  };

  const transformToOrder = (event) => {
    event.stopPropagation();
    props.onOrder(props.quote);
  };

  return (
    <div className={classes.preview} onClick={detailsHandler}>
      <div className={classes.previewIcon}>
        {props.quote.status == 1 && (
          <>
            {/* <SaveIcon className={`${classes.icon} ${classes.iconSaved}`} /> */}
            <div className={classes.content}>
              <h4>Presupuesto pendiente</h4>
              <input
                type="button"
                value="Realizar pedido"
                onClick={transformToOrder}
                className="btn-secondary"
              />
            </div>
          </>
        )}

        {props.quote.status != 1 && (
          <>
            <DoneIcon className={`${classes.icon} ${classes.iconProcessed}`} />
            <h4>Presupuesto aceptado</h4>
          </>
        )}
      </div>

      <div className={classes.previewDetails}>
        <h5>
          {props.quote.reference
            ? `# ${props.quote.id} ${props.quote.reference}`
            : `# ${props.quote.id}`}
        </h5>
        <h5 className={classes.light}>{props.quote.created_at}</h5>
      </div>
      <RightArrowIcon
        className={classes.rightIcon}
        style={{ fontSize: "1.5rem" }}
      />
    </div>
  );
};

export default QuotePreview;
