import redaxios from "redaxios";

const domain = process.env.REACT_APP_BACKEND_URL;

export const brandsList = async ({ queryKey }) => {
  const [key, { token }] = queryKey;

  const result = await redaxios.get(`${domain}/api/quote/brands`, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const modelsList = async ({ queryKey }) => {
  const [key, { token, brand }] = queryKey;

  const result = await redaxios.get(
    `${domain}/api/quote/models/${brand.value}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const yearsList = async ({ queryKey }) => {
  const [key, { token, selectedModel }] = queryKey;

  const result = await redaxios.get(
    `${domain}/api/quote/years/${selectedModel.value}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};

export const generationsList = async ({ queryKey }) => {
  const [key, { token, model, year }] = queryKey;

  const result = await redaxios.get(
    `${domain}/api/quote/generations/${model.value}/${year.value}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return result.data;
};
