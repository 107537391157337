import UpIcon from "./UpIcon";
import classes from "./ScrollUp.module.css";

const ScrollUp = () => {
  const scrollToTopHandler = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div onClick={scrollToTopHandler} className={classes.scroll}>
      <UpIcon className={classes.icon} />
    </div>
  );
};

export default ScrollUp;
