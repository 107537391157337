import classes from "./PointsIndicator.module.css";
import { Tooltip } from "react-tippy";
import InformationIcon from "../ui/icons/InformationIcon";
import StartIcon from "../ui/icons/StarIcon";

const PointsIndicador = (props) => {
  return (
    <div className={classes.container}>
      <div className={classes.pointer}>
        <StartIcon className={classes.star} />
        <p>{props.points} puntos</p>
      </div>
      <div>
        <Tooltip
          title="Los puntos se acumulan en tu cuenta y los puedes convertir en descuentos para tus pedidos particulares"
          trigger="click">
          <InformationIcon />
        </Tooltip>
      </div>
    </div>
  );
};
export default PointsIndicador;
