import React, { useEffect, useState } from "react";
import UnitsSelector from "./../UnitsSelector/UnitsSelector";
import MaterialSelector from "../MaterialSelector/MaterialSelector";
import classes from "./Product.module.css";
import ProductPrice from "../../../../components/Quotes/ProductPrice";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { productPrice } from "../../../../lib/products";
import { addCartProducts } from "../../../../lib/cart";
import { success, toastError } from "../../../../lib/toast";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";

const Product = (props) => {
  // onAddProducts

  const [material, setMaterial] = useState(null);
  const [units, setUnits] = useState(0);
  const [productElegible, setProductElegible] = useState(false);
  const [theresPrice, setTheresPrice] = useState(false);

  // Transforma formato opciones material a Select2
  // const materialsOptions = [];
  // props.materials.map((material) =>
  //   materialsOptions.push({
  //     label: `${material.name} - ${material.reference}`,
  //     value: material.id,
  //   })
  // );

  const materialSelectHandler = (material) => {
    setMaterial(material);
    if (units == 0) {
      onAddUnitsHandler();
    }
  };

  // Handler para seleccionar las unidades
  const onAddUnitsHandler = () => setUnits((prevState) => prevState + 1);
  const onRemoveUnitsHandler = () => setUnits((prevState) => prevState - 1);

  const queryClient = useQueryClient();

  // Dependent Query para recuperar el precio unitario
  const priceQuery = useQuery(
    [
      "productUnitPrice",
      {
        token: props.token,
        generation: props.generation.generation_id,
        material: material?.value,
        product: props.product.id,
      },
    ],
    productPrice,
    { enabled: units > 0 && !!material?.value },
    {
      onSuccess: () => setTheresPrice(true),
    }
  );

  // Mutation Query
  const addProductToCartMutation = useMutation(addCartProducts, {
    onSuccess: (data) => {
      success(`Se ha añadido el producto a su presupuesto actual`);
      queryClient.invalidateQueries("cartList");
    },
    onError: () =>
      toastError(`Ha ocurrido u error y no se ha podido procesar su petición`),
  });

  useEffect(() => {
    units > 0 && priceQuery.data?.price >= 0 && !!material
      ? setProductElegible(true)
      : setProductElegible(false);
  }, [units, material, priceQuery.data?.price]);

  // Handler para añadir al carrito
  const addProcutToCardHandler = () => {
    if (units > 0 && material) {
      try {
        addProductToCartMutation.mutate({
          token: props.token,
          values: {
            product_id: props.product.id,
            product_name: props.product.name,
            material_id: material.value,
            material_name: material.label,
            units: units,
            price: priceQuery.data.price,
            generation_id: props.product.generation_id,
            generation_name: props.generation.generation_name,
            discount: props.discount,
          },
        });
      } catch (error) {
        toastError();
      }
    }
  };

  // console.log(`http://192.168.0.159:8000/${props.product.image_path}`);
  // console.table(props.product);

  return (
    <div className={classes.product}>
      <div className={classes.productImage}>
        <img
          src={`http://192.168.0.159:8000/${props.product.image_path}`}
          alt={`${props.product.name}`}
        />
      </div>
      <div className={classes.productProps}>
        <p className={classes.productName}>{props.product.name}</p>
        <p className={classes.description}>
          {props.product.description && props.product.description}
        </p>
        {/* Materiales */}
        <MaterialSelector
          materials={props.materials}
          onSelect={materialSelectHandler}
        />
        <div className={classes.selects}>
          {/* Unidades */}
          <UnitsSelector
            onAdd={onAddUnitsHandler}
            onRemove={onRemoveUnitsHandler}
            isActive={!!material}
            value={units}
          />

          <ProductPrice
            unitPrice={priceQuery.data?.price}
            units={units}
            discount={props.discount}
          />

          {/* Esto deberia de ir dentro del componente ProductPrice */}
          {!props.product.generation_id &&
            priceQuery.data?.price &&
            units > 0 && (
              <p className={classes.notFinalPrice}>
                Este es un precio orientativo tu taller Motorvinilo te lo
                confirmará antes de procesar tu pedido
              </p>
            )}

          {/* Carrito */}
          <div
            className={`${classes.cart} ${
              productElegible ? classes.active : classes.disabled
            }`}
            onClick={addProcutToCardHandler}>
            <Tooltip
              title="Para añadir este servicio a tu presupuesto debes seleccionar un color y unidades"
              position="top"
              trigger="click"
              disabled={!!material && units > 0}>
              <input
                type="button"
                value="Añadir al carrito"
                className={`${
                  productElegible ? "btn-active" : classes.btnDisabled
                } ${classes.btn}`}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

const MemoizedProduct = React.memo(Product);

export default MemoizedProduct;
