import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { SessionContextProvider } from "./context/SessionContext";
import { AuthContextProvider } from "./context/AuthContext";
import Footer from "./components/ui/Footer/Footer";

ReactDOM.render(
  <BrowserRouter>
    <SessionContextProvider>
      <AuthContextProvider>
        <div className="page-container">
          <div className="content-wrap">
            <App />
          </div>
          {/* <Footer /> */}
        </div>
      </AuthContextProvider>
    </SessionContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
