// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Pagination, Scrollbar } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import classes from "./MainService.module.css";
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const portalElement = document.getElementById("overlays");

const FullScreenBackdrop = (props) => {
  return <div className={classes.fullScreenBox} />;
};

const FullScreenImg = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes.content}>
        <img src={props.image} />
      </div>
    </div>
  );
};

const FullScreen = (props) => {
  useLockBodyScroll();

  return (
    <>
      {ReactDOM.createPortal(<FullScreenBackdrop />, portalElement)}
      {ReactDOM.createPortal(
        <FullScreenImg image={props.image} />,
        portalElement
      )}
    </>
  );
};

const MainService = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fullScreenHandler = (image) => {
    setIsFullScreen(true);
    setSelectedImage(image);
  };

  useEffect(() => {
    console.log(selectedImage);
  }, [selectedImage]);

  return (
    <div className={classes.container}>
      {isFullScreen && <FullScreen image={selectedImage} />}

      <h3>{props.title}</h3>
      <div className={classes.images}>
        <Swiper
          scrollbar={{ hide: true }}
          breakpoints={{
            550: {
              slidesPerView: 2.2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 3.3,
              spaceBetween: 10,
            },
            1100: {
              slidesPerView: 4.3,
              spaceBetween: 10,
            },
          }}
          pagination={{
            clickable: false,
          }}
          slidesPerView={1.3}
          spaceBetween={10}
          modules={[Scrollbar]}
          loop={true}
          className={classes.swiperWrapper}>
          {props.photos.map((image, index) => (
            <SwiperSlide key={index}>
              <div className={classes.img}>
                <img src={image} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default MainService;
