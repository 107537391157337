import classes from "./Header.module.css";

const Header = (props) => {
  return (
    <div className={`${classes.header} ${props.className}`}>
      {props.children}
    </div>
  );
};

export default Header;
