import { useState } from "react";
import Header from "../../components/Header/Header";
import MainService from "../../components/MainServices";
import Breadcrumbs from "../../components/ui/Breadcrumbs/Breadcrumbs";
import ScrollUp from "../../components/ui/ScrollUp/ScrollUp";
import Images from "./components/Images";
import classes from "./Gallery.module.css";
import { SERVICES } from "./services";

const Gallery = () => {
  const [activeTab, setActiveTab] = useState("images");

  const tabsHandler = (tab) => setActiveTab(tab);

  const mainServices = SERVICES;

  return (
    <div className={classes.container}>
      <Breadcrumbs />
      <ScrollUp />

      <Header>
        <h1>Galería de imágenes</h1>
      </Header>

      <main>
        <div className={classes.tabs}>
          <div
            onClick={() => tabsHandler("images")}
            className={`${classes.tab} ${
              activeTab == "images" && classes.active
            }`}>
            Galeria de imágenes
          </div>
          <div
            onClick={() => tabsHandler("services")}
            className={`${classes.tab} ${
              activeTab == "services" && classes.active
            }`}>
            Servicios habituales
          </div>
        </div>
        <div className={classes.content}>
          {activeTab == "images" && <Images />}
          {activeTab == "services" &&
            mainServices[1].data.map((service, index) => (
              <MainService
                title={service.service}
                photos={service.photos}
                key={index}
              />
            ))}
        </div>
      </main>
    </div>
  );
};

export default Gallery;
