import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Context from "../../../context/SessionContext";
import classes from "./Nav.module.css";
import Sidebar from "./Sidebar";
import MenuIcon from "../icons/MenuIcons";
import ShoppingCart from "./ShoppingCart";
import useUserData from "../../../hooks/Auth/useUserData";

const Nav = () => {
  const { token, logout } = useUserData();

  const [isSidebarShown, setIsSidebarShown] = useState(false);
  const showSidebarHandler = () => {
    if (token) {
      setIsSidebarShown(true);
    }
  };
  const hideSidebarHandler = () => setIsSidebarShown(false);

  const logoutHandler = async () => {
    await logout();
    setIsSidebarShown(false);
  };

  return (
    <nav className={classes.container}>
      <div onClick={showSidebarHandler} className={classes.left}>
        {token && (
          <>
            <p>Menu</p>
            <MenuIcon style={{ color: "white", fontSize: "2.2rem" }} />
          </>
        )}
      </div>

      <div className={classes.right}>
        {token && (
          <Link to="/cart">
            <ShoppingCart />
          </Link>
        )}

        <Link to="/">
          <img
            src="https://www.motorvinilo.com/img/motorvinilo-logo-1489316817.jpg"
            alt=""
            className={classes.imgLogo}
          />
        </Link>
      </div>

      {isSidebarShown && (
        <Sidebar onClose={hideSidebarHandler} onLogout={logoutHandler} />
      )}
    </nav>
  );
};

export default Nav;
