import { useEffect, useState } from "react";
import Color from "../Color";
import classes from "./ColorsSelector.module.css";

const COLORS = [
  {
    id: 9,
    color: "Negro",
    background: "black",
  },
  {
    id: 40,
    color: "Rojo",
    background: "red",
  },
  {
    id: 12,
    color: "Verde",
    background: "green",
  },
  {
    id: 70,
    color: "Azul",
    background: "blue",
  },
  {
    id: 23,
    color: "Blanco",
    background: "white",
  },
  {
    id: 65,
    color: "Naranja",
    background: "orange",
  },
  {
    id: 117,
    color: "Oro",
    background: "gold",
  },
  {
    id: 90,
    color: "Cepillado",
    background: "blue",
  },
];

const ColorsSelector = (props) => {
  const [colorSelected, setColorSelected] = useState(null);

  useEffect(() => {
    props.onSelect(colorSelected);
    // alert(colorSelected);
  }, [colorSelected]);

  const setColor = (color) => setColorSelected(color);

  const resetColor = () => setColorSelected(null);

  return (
    <div className={classes.container}>
      <div className={classes.operations}>
        <input
          type="button"
          value="Desactivar filtro"
          className={`${colorSelected ? "btn-cancel" : "btn-disabled"} ${
            classes.button
          }`}
          onClick={resetColor}
        />
        {colorSelected && <span>Filtrando por "{colorSelected}"</span>}
      </div>
      <div className={classes.selector}>
        <Color
          color={"Negro"}
          className={classes.black}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Rojo"}
          className={classes.red}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Verde"}
          className={classes.green}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Azul"}
          className={classes.blue}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Blanco"}
          className={classes.white}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Naranja"}
          className={classes.orange}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Oro"}
          className={classes.gold}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Cepillado"}
          className={classes.brush}
          onSelect={setColor}
          selected={colorSelected}
        />

        <Color
          color={"Carbono"}
          className={classes.carbon}
          onSelect={setColor}
          selected={colorSelected}
        />
      </div>
    </div>
  );
};

export default ColorsSelector;
