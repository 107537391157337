import useUserPermissions from "../../hooks/Auth/useUserPermissions";

const CanCreateUsers = (props) => {
  const { userCan } = useUserPermissions();

  if (userCan("user.create")) {
    return <>{props.children}</>;
  }

  return null;
};

export default CanCreateUsers;
