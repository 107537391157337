import { createContext } from "react";
import useLocalStorage from "../hooks/useStorage";
import { login, logout as destroySession } from "../lib/sessions";

const authContext = createContext({});

export const AuthContextProvider = (props) => {
  const { storedValue, setValue } = useLocalStorage("userData", {});

  const loginHandler = async (email, password) => {
    const response = await login(email, password);
    setValue(response);
  };

  const logout = async () => {
    try {
      await destroySession(storedValue.token);
      setValue({});
    } catch (error) {
      console.log(error.message);
    }
  };

  const contextValue = {
    token: storedValue.token,
    permissions: storedValue.permissions || [],
    name: storedValue.name,
    email: storedValue.email,
    loginHandler,
    logout,
  };

  return (
    <authContext.Provider value={contextValue}>
      {props.children}
    </authContext.Provider>
  );
};

export default authContext;
