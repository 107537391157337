import classes from "./CompaniesList.module.css";
import CanSwapAccount from "../../../components/Permissions/CanSwapAccount";
import Card from "../../../components/ui/Card/Card";
import Error from "../../../components/ui/Error/Error";
import Loading from "../../../components/ui/Loading/Loading";
import MainContainer from "../../../components/ui/MainContainer";
import useCompanies from "../../../hooks/Company/useCompanies";
import { useNavigate } from "react-router-dom";

const CompaniesList = () => {
  const { companies } = useCompanies();

  const navigate = useNavigate();

  if (companies.isLoading) {
    return <Loading />;
  }

  if (companies.isError) {
    return <Error />;
  }

  const crateHandler = () => navigate("/companies/new");

  return (
    <MainContainer>
      <CanSwapAccount>
        <h1>Empresas</h1>

        <main className={classes.main}>
          <div className={classes.buttons}>
            {/* <button className="btn-active" onClick={crateHandler}>
              Añadir empresa
            </button> */}
          </div>

          <Card>
            <div className={classes.companies}>
              <div className={classes.company}>
                <p className={`${classes.column} ${classes.header}`}>Empresa</p>
                <p className={`${classes.column} ${classes.header}`}>Email</p>
              </div>

              {companies.data.map((company) => (
                <div
                  className={`${classes.company} ${classes.row}`}
                  key={company.id}>
                  <p className={`${classes.column}`}>{company.company_name}</p>
                  <p className={`${classes.column}`}>{company.email}</p>
                </div>
              ))}
            </div>
          </Card>
        </main>
      </CanSwapAccount>
    </MainContainer>
  );
};

export default CompaniesList;
