import { useMutation, useQueryClient } from "react-query";
import { saveCartList } from "../../lib/cart";
import { createOrder } from "../../lib/quote";
import useUserData from "../Auth/useUserData";

const useQuoteService = () => {
  const { token } = useUserData();

  const queryClient = useQueryClient();

  // mutation para transformar carrito en quote
  const saveCart = useMutation(
    async () => {
      const result = await saveCartList(token);
      return result;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("settings");
        queryClient.invalidateQueries("cartList");
        queryClient.invalidateQueries("usePoints");
      },
    }
  );

  // muation para transformar quote en order
  const transFormToOrder = useMutation(async (quoteId) => {
    const result = await createOrder(token, quoteId);
    return result;
  });

  // mutation para transformar carrito en order
  const saveAndTransform = useMutation(async () => {
    const quote = await saveCartList(token);
    const order = await createOrder(token, quote.id);

    return order;
  });

  return { saveCart, transFormToOrder, saveAndTransform };
};

export default useQuoteService;
