import classes from "./UpdatePassword.module.css";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import MainContainer from "../../../components/ui/MainContainer";
import useUpdatePassword from "../../../hooks/Users/useUpdatePassword";
import Loading from "../../../components/ui/Loading/Loading";

const UpdatePassword = () => {
  const { refreshToken } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { updatePassword } = useUpdatePassword();

  const onSubmit = (data) => {
    updatePassword.mutate({ ...data, refreshToken });
  };

  if (updatePassword.isLoading) {
    return <Loading />;
  }

  return (
    <MainContainer>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.formBox}>
          <div className={classes.input}>
            <label className={classes.label}>Nueva contraseña</label>
            <input
              type="password"
              name="newPassword"
              {...register("newPassword", { required: true })}
            />
            {errors.newPassword && (
              <span className={classes.error}>
                El campo contraseña es obligatorio.
              </span>
            )}
          </div>
        </div>

        <div className={classes.buttons}>
          <button type="submit" className={`btn-active ${classes.btn}`}>
            Cambiar contraseña
          </button>
        </div>
      </form>
    </MainContainer>
  );
};

export default UpdatePassword;
