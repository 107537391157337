import { useContext, useState } from "react";
import { useQuery } from "react-query";
import GalleryComponent from "../../../components/Gallery/Gallery";
import Error from "../../../components/ui/Error/Error";
import Context from "../../../context/SessionContext";
import usePageTitle from "../../../hooks/usePageTitle";
import { getAllPhotos, getPhotos, getTags } from "../../../lib/gallery-api";
import ColorsSelector from "../components/ColorsSelector";
import classes from "./Images.module.css";
import LoadingIcon from "../../../components/ui/icons/LoadingIcon";

const Images = () => {
  usePageTitle(`B2B - Motorvinilo - Galeria de imagenes`);

  const session = useContext(Context);
  const token = session?.storedValue.token;

  const [color, setColor] = useState(null);

  const selectHandler = (value) => {
    setColor(value);
    // console.log(value);
    // alert(value);
  };

  // Recupera listado de colores
  const tagsQuery = useQuery(["colors", { token }], getTags, {
    enabled: false,
  });

  // Recupera listado de fotod
  const photosQuery = useQuery(["photos", { token, color }], getPhotos, {
    enabled: !!color,
  });

  const allPhotosQuery = useQuery(["allPhotos", { token }], getAllPhotos);

  // El spinner de photosQuery deberia de salir debajo de los colores y no bloquear la navegación
  if (
    tagsQuery.isLoading ||
    allPhotosQuery.isLoading
  ) {
    return (
      <div className={classes.loading}>
        <LoadingIcon />
      </div>
    );
  }

  if (tagsQuery.isError || photosQuery.isError || allPhotosQuery.isError) {
    return <Error />;
  }

  return (
    <div className={classes.container}>
      {/* <Breadcrumbs /> */}
      <ColorsSelector onSelect={selectHandler} />
      <h3>Fotos</h3>
      {allPhotosQuery?.data?.length > 0 && !color && (
        <div className={classes.gallery}>
          <GalleryComponent images={allPhotosQuery.data} />
        </div>
      )}
      {photosQuery?.data?.length > 0 && (
        <div className={classes.gallery}>
          <GalleryComponent images={photosQuery.data} />
        </div>
      )}
    </div>
  );
};

export default Images;
