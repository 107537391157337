import classes from "./Unauthorized.module.css";

const Unauthorized = () => {
  return (
    <div className={classes.unauthorized}>
      <span>
        El contenido al que estás intentando acceder tiene el acceso
        restringido. Por favor contacta con MotorVinilo.
      </span>
    </div>
  );
};

export default Unauthorized;
