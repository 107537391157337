import classes from "./Gallery.module.css";
import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery, Item } from "react-photoswipe-gallery";

const GalleryComponent = (props) => {
  return (
    <Gallery shareButton={false} options={{ closeOnScroll: false }}>
      {props.images.map((image, index) => {
        return (
          <Item
            original={`${image.full_url}`}
            thumbnail={`${image.full_url}`}
            width="1024"
            height="768"
            key={index}
            title={image.name}>
            {({ ref, open }) => (
              <img
                ref={ref}
                onClick={open}
                src={`${image.full_url}`}
                className={classes.full}
                loading='lazy'
              />
            )}
          </Item>
        );
      })}
    </Gallery>
  );
};

export default GalleryComponent;
